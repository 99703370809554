﻿*,
:after,
:before {
  box-sizing: border-box;
}

// Main content layout
.#{$prefix}main-content {
  height: 100vh;
  overflow-y: scroll;
}
