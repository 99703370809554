// !important is needed for some visibility classes

// Show for screen readers only. Firstly we should set everthing as invisible.
.#{$prefix}show-for-sr,
.#{$prefix}show-on-focus {
  @include element-invisible;
}

// Only display the element when it's focused.
.#{$prefix}show-on-focus {
  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

// Show elements in landscape mode.
.#{$prefix}show-for-landscape,
.#{$prefix}hide-for-portrait {
  display: block !important;

  @media screen and (orientation: landscape) {
    display: block !important;
  }

  @media screen and (orientation: portrait) {
    display: none !important;
  }
}

// Show elements in portrait mode.
.#{$prefix}hide-for-landscape,
.#{$prefix}show-for-portrait {
  display: none !important;

  @media screen and (orientation: landscape) {
    display: none !important;
  }

  @media screen and (orientation: portrait) {
    display: block !important;
  }
}
