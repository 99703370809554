﻿$spacing-4:   4px !default;
$spacing-8:   8px !default;
$spacing-12: 12px !default;
$spacing-16: 16px !default;
$spacing-20: 20px !default;
$spacing-24: 24px !default;
$spacing-28: 28px !default;
$spacing-32: 32px !default;
$spacing-36: 36px !default;
$spacing-64: 64px !default;
$spacing-96: 96px !default;

// Control the default styling of most Bootstrap elements by modifying these

$spacer: $spacing-16 !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    4:  px-to-rem($spacer * .25),
    8:  px-to-rem($spacer * .5),
    16: px-to-rem($spacer),
    24: px-to-rem($spacer * 1.5),
    32: px-to-rem($spacer * 2),
    64: px-to-rem($spacer * 4),
    96: px-to-rem($spacer * 6)
  ),
    $spacers
);
