@mixin transition-with-curve ($curve-type, $duration: 'default', $transition-for...) {
  @include transition-timing-function (map-get($motion-curve, $curve-type));

  @if ($duration == 'default') {
    $duration: map-get($motion-default-duration, $curve-type);
  }

  @include transition-duration ($duration);

  @if (index($transition-for, all) != null) {
    @warn 'Avoid using transition-property: all due to performance issues. Please specify the property/properties to be animated.';
  }

  transition-property: $transition-for;
}
