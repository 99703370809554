$header-class-name: #{$prefix}header;
$header-spacing: 16px;
$header-condensed-spacing: 14px;
$header-bottom-spacing: 24px;
$header-bottom-border: 1px solid $color-grey-34;
$header-background-color: $color-white;
$header-title-margin-top: 8px;
$header-action-min-height: 28px;
$header-breadcrumb-margin-bottom: 8px;
$header-tabs-margin-top: 8px;
$header-subtitle-color: $color-grey-120;
$header-subtitle-margin: 4px 0 0;