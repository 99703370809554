// Table
$table-class-name: #{$prefix}table !default;
$table-border: 1px solid $color-grey-34;
$table-background: $color-white;

// Table row
$table-row-height: 36px;
$table-row-x-padding: 1.5rem;
$table-row-border-bottom: 1px solid $color-grey-18;
$table-row-hover-bg: $color-paleblue;
$table-row-selected-bg: $color-lightblue;
$table-row-selected-font-weight: $font-weight-bold;
$table-row-draggable-border-top: 2px solid $color-blue; 
$table-row-draggable-background-color: $color-paleblue;
$table-row-draggable-box-shadow: $clist-item-ondrag-shadow;

// Table header
$table-header-height: 20px;
$table-header-color: $color-grey-120;
$table-header-font-size: px-to-rem($caption-font-size);
$table-header-font-weight: $font-weight-normal;
$table-header-line-height: $caption-line-height;
$table-header-cell-edit-bg: $color-paleblue;
$table-header-sort-elements-margin: 8px;
$table-header-sort-icon-size: 12px;

// Table cell
$table-cell-x-padding: .5rem;
$table-cell-height: 26px;
$table-cell-editable-height: 33px;
$table-cell-editable-padding: px-to-rem(3px) px-to-rem(8px);
$table-cell-editable-border: 1px solid $input-border-color-hover;
$table-cell-editable-border-radius: 2px;
$table-cell-editable-background-color: $color-white;
$table-cell-editable-box-shadow: $button-shadow;
$table-cell-editable-notification-border: 2px solid $color-blue;

// Table select
$table-select-width: 49px;
$table-select-padding-top: px-to-rem(6px);
$table-select-checkbox-height: 24px;

// Table subgroup
$table-subgroup-background-color: $color-grey-blue-pale;
$table-subgroup-color: $color-grey-120;
$table-subgroup-font-size: px-to-rem($caption-font-size);

// Table actions
$table-actions-width: 133px;
$table-actions-elements-margin-right: 16px;
$table-actions-icon-color: $color-grey-unit4;

// Table toolbar
$table-toolbar-btn-margin: 0 0 .5rem;

// Table bulk
$table-bulk-top: -4px;
$table-bulk-padding: px-to-rem(3px) 1.5rem;
$table-bulk-background-color: $color-paleblue;
$table-bulk-elements-margin: 8px;

// Table footer
$table-footer-small-text-color: $color-grey-120;
