.#{$prefix}btn {
  @include button;
  @include button-size();
  @include button-style();
}

.#{$prefix}btn--small {
  @include button-size(small);
}

.#{$prefix}btn--large {
  @include button-size(large);
}

.#{$prefix}btn--happy {
  @include button-style(happy);
}

.#{$prefix}btn--positive {
  @include button-style(positive);
}

.#{$prefix}btn--negative {
  @include button-style(negative);
}

.#{$prefix}btn--negative-secondary {
  @include button-style(negative-secondary);
}

.#{$prefix}btn--link {
  @include button-style(link);
}
