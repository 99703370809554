.carousel-controls {
  display: flex;
  height: 50px;
  border: 0;
  margin: 0;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  color: #0d78aa; }
  .carousel-controls .number-pagination {
    display: inline-flex; }
  .carousel-controls .dots-pagination {
    margin-left: 1em;
    margin-right: 1em; }
  .carousel-controls .icon-btn {
    margin: 0; }
  .carousel-controls .form__item {
    min-width: auto;
    max-width: auto;
    width: auto;
    border: 0;
    margin: 0 .5rem;
    background-color: transparent; }
    .carousel-controls .form__item input {
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      border: 0;
      padding: 0;
      text-align: center;
      background-color: transparent;
      color: #0d78aa; }
      .carousel-controls .form__item input:read-only {
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: textfield; }
      .carousel-controls .form__item input:focus:not(:read-only) {
        border: 1px;
        background-color: #fff;
        color: #000; }

.document-viewer__carousel-toolbar {
  color: #fff; }
  .document-viewer__carousel-toolbar .form__item input {
    color: #fff; }
