$button-padding-default: px-to-rem($spacing-16) !default;
$button-margin-default: $spacing-16 !default;
$button-max-height-default: 44px !default;
$button-min-width-default: 88px !default;
$button-font-size-default: px-to-rem($font-size-body) !default;
$button-font-color-default: $color-grey-120;
$button-font-color-default-hover: $color-black;
$button-background-default: $color-white !default;
$button-background-default-hover: $color-paleblue !default;
$button-background-default-active: $color-lightblueselect !default;
$button-border-radius: 4px !default;
$button-border-style: solid !default;
$button-border-width: 1px !default;
$button-border-color-default: $color-grey-65 !default;
$button-border-color-default-hover: $color-grey !default;
$button-shadow-color: $color-blue !default;
$button-shadow: 0 0 10px $button-shadow-color !default;

$button-background-happy: $color-blue !default;
$button-background-happy-hover: $color-btn-happy-hover !default;
$button-background-happy-active: $color-btn-happy-press !default;
$button-font-color-happy: $color-white !default;

$button-background-positive: $color-success !default;
$button-background-positive-hover: mix($color-success, $color-black, 80%) !default;
$button-background-positive-active: mix($color-success, $color-black, 60%) !default;
$button-font-color-positive: $color-white !default;

$button-background-negative: $color-alert !default;
$button-background-negative-hover: mix($color-alert, $color-black, 80%) !default;
$button-background-negative-active: mix($color-alert, $color-black, 60%) !default;
$button-font-color-negative: $color-white !default;

$button-border-color-negative-secondary: $color-alert !default;
$button-background-negative-secondary: $color-white !default;
$button-background-negative-secondary-hover: $color-alert !default;
$button-background-negative-secondary-active: mix($color-alert, $color-black, 60%) !default;
$button-font-color-negative-secondary: $color-alert !default;
$button-font-color-negative-secondary-hover: $color-white !default;

$button-padding-small: px-to-rem($spacing-8) !default;
$button-max-height-small: 32px !default;
$button-min-width-small: 64px !default;
$button-font-size-small: px-to-rem(14px) !default;

$button-padding-large: px-to-rem($spacing-24) !default;
$button-max-height-large: 52px !default;
$button-min-width-large: 104px !default;
$button-font-size-large: px-to-rem($font-size-html) !default;

$button-padding-link: 0 px-to-rem(4px) !default;
$button-font-color-link: $color-blue !default;
$button-font-color-link-hover: mix($color-blue, $color-black, 80%) !default;
$button-font-color-link-active: mix($color-blue, $color-black, 60%) !default;
$button-icon-size-default: 24px;
$button-icon-size-small: 16px;
$button-icon-margin-right: 4px;
$button-icon-margin-bottom: .3em;

$button-padding: (
  default: 0 $button-padding-default,
  small: 0 $button-padding-small,
  large: 0 $button-padding-large,
);

$button-max-height: (
  default: $button-max-height-default,
  small: $button-max-height-small,
  large: $button-max-height-large,
);

$button-min-width: (
  default: $button-min-width-default,
  small: $button-min-width-small,
  large: $button-min-width-large
);

$button-font-size: (
  default: $button-font-size-default,
  large: $button-font-size-large
);

$button-icon-size: (
  default: $button-icon-size-default,
  small: $button-icon-size-small,
  large: $button-icon-size-default
);

$button-font-color: (
  default: $button-font-color-default,
  happy: $button-font-color-happy,
  positive: $button-font-color-positive,
  negative: $button-font-color-negative,
  negative-secondary: $button-font-color-negative-secondary,
  link: $button-font-color-link
);

$button-font-color-hover: (
  default: $button-font-color-default-hover,
  happy: $button-font-color-happy,
  positive: $button-font-color-positive,
  negative: $button-font-color-negative,
  negative-secondary: $button-font-color-negative-secondary-hover,
  link: $button-font-color-link-hover
);

$button-font-color-active: (
  default: $button-font-color-default-hover,
  link: $button-font-color-link-active
);

$button-border-color: (
  default: $button-border-color-default,
  happy: transparent,
  positive: transparent,
  negative: transparent,
  negative-secondary: $button-border-color-negative-secondary
);

$button-border-color-hover: (
  default: $button-border-color-default-hover,
  happy: transparent,
  positive: transparent,
  negative: transparent,
  negative-secondary: $button-border-color-negative-secondary
);

$button-border-color-active: (
  happy: transparent,
  positive: transparent,
  negative: transparent,
  negative-secondary: $button-background-negative-secondary-active
);

$button-background: (
  default: $button-background-default,
  happy: $button-background-happy,
  positive: $button-background-positive,
  negative: $button-background-negative,
  negative-secondary: $button-background-negative-secondary,
  link: transparent
);

$button-background-hover: (
  default: $button-background-default-hover,
  happy: $button-background-happy-hover,
  positive: $button-background-positive-hover,
  negative: $button-background-negative-hover,
  negative-secondary: $button-background-negative-secondary-hover,
  link: transparent
);

$button-background-active: (
  default: $button-background-default-active,
  happy: $button-background-happy-active,
  positive: $button-background-positive-active,
  negative: $button-background-negative-active,
  negative-secondary: $button-background-negative-secondary-active,
  link: transparent
);
