$message-bar-class-name: #{$prefix}message-bar !default;
$message-bar-padding: .5rem 1rem;
$message-bar-error-background: $color-alert;
$message-bar-error-color: $color-white;
$message-bar-error-link-color: $color-white;
$message-bar-warning-border-bottom: 1px solid $color-grey-34;
$message-bar-warning-background-color: $color-warning-pale;
$message-bar-icon-margin-right: 8px;
$message-bar-error-icon-color: $color-white;
$message-bar-warning-icon-color: $color-warning;
$message-bar-close-icon-size: 16px;
$message-bar-close-icon-margin-left: 16px;
$message-bar-close-icon-button-size: 24px;
$message-bar-warning-close-icon-color: $color-grey-unit4;
$message-bar-error-close-icon-color: $color-white;
$message-bar-read-more-margin-left: 8px;