﻿// Define button size
@mixin button-size($size: default) {
  max-height: map-get($button-max-height, #{$size});
  padding: map-get($button-padding, #{$size});
  font-size: map-get($button-font-size, #{$size});
  line-height: map-get($button-max-height, #{$size});

  .#{$fa-icon-selector} {
    width: map-get($button-icon-size, #{$size});
    height: map-get($button-icon-size, #{$size});
  }
}

// Define default button styles
@mixin button {
  display: inline-block; // This is needed
  min-width: $button-min-width-default;
  margin: $button-margin-default;
  border-width: $button-border-width;
  border-style: $button-border-style;
  border-radius: $button-border-radius;
  cursor: pointer;
  font-weight: $font-weight-bold;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @include transition-with-curve (smooth-fast, 'default', color, background-color, box-shadow);

  .#{$fa-icon-selector} {
    margin-right: $button-icon-margin-right;
    margin-bottom: $button-icon-margin-bottom;
    vertical-align: middle;

    path {
      @include transition-with-curve (smooth-fast, 'default', fill);
    }
  }

  &:after,
  &:before {
    display: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: $button-shadow;
  }

  &:disabled,
  &.is-disabled {
    cursor: default;
    opacity: .3;
    pointer-events: none;
  }
}

// Define button styles
@mixin button-style($style: default) {
  @if $style == 'link' {
    min-width: auto;
    max-height: $button-max-height-small;
    padding: $button-padding-link;
    border: 0;
    border-radius: 0;
    line-height: $button-max-height-small;
  }

  border-color: map-get($button-border-color, #{$style});
  background-color: map-get($button-background, #{$style});
  color: map-get($button-font-color, #{$style});

  &:hover,
  &:focus,
  &:focus:hover {
    border-color: map-get($button-border-color-hover, #{$style});
    background-color: map-get($button-background-hover, #{$style});
    color: map-get($button-font-color-hover, #{$style});

    .#{$fa-icon-selector} path {
      fill: map-get($button-font-color-hover, #{$style});
    }
  }

  &:active,
  &:focus:active {
    border-color: map-get($button-border-color-active, #{$style});
    background-color: map-get($button-background-active, #{$style});
    color: map-get($button-font-color-active, #{$style});

    @if $style == 'link' {
      box-shadow: $button-shadow;
    }
  }

  .#{$fa-icon-selector} path {
    fill: map-get($button-font-color, #{$style});
  }
}
