.flex-container.split-container {
  padding: 0; }

.main-container__body > .SplitPane > .Pane1 {
  margin-right: -1px; }

.main-container__body > .SplitPane.heigth-transition > .Pane2, .main-container__body > .SplitPane.width-transition > .Pane2 {
  min-width: 75% !important;
  max-width: 75% !important;
  width: 75% !important; }

.SplitPane {
  position: relative !important;
  min-width: 350px;
  flex-wrap: initial !important; }
  .SplitPane.width-transition .Pane1 {
    transition: none; }
  .SplitPane.hide-splitter > .Resizer {
    background: transparent; }
  .SplitPane .Pane1 .scrollbar-container {
    padding-right: 0.25em; }
    .SplitPane .Pane1 .scrollbar-container .ps__rail-x, .SplitPane .Pane1 .scrollbar-container .ps__rail-y {
      z-index: 4; }
  .SplitPane .Pane {
    overflow: auto; }
    .SplitPane .Pane .document-viewer__wrapper {
      margin: 0; }
  .SplitPane.hide-splitter .Resizer:after {
    display: none; }

.Resizer {
  background: #d2d6d9;
  box-sizing: border-box;
  background-clip: padding-box;
  opacity: 1;
  z-index: 5; }
  .Resizer:hover {
    transition: all 2s ease; }
  .Resizer.horizontal:after, .Resizer.vertical:after {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    border: solid 1px #d2d6d9;
    border-radius: 20%;
    content: ''; }
  .Resizer.horizontal.disabled:after, .Resizer.vertical.disabled:after {
    display: none; }
  .Resizer.horizontal {
    width: 100%;
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize; }
    .Resizer.horizontal:after {
      height: 8px;
      width: 35px;
      left: 50%;
      margin-top: -4px;
      background-image: url("./images/ellipsis-h.svg");
      background-size: 30%; }
    .Resizer.horizontal:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5); }
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize; }
    .Resizer.vertical:after {
      height: 35px;
      width: 8px;
      top: 50%;
      margin-left: -4px;
      background-image: url("./images/ellipsis-v.svg");
      background-size: 75%; }
    .Resizer.vertical:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
      opacity: .2; }
  .Resizer.disabled {
    cursor: default; }
    .Resizer.disabled:hover {
      border-color: transparent; }
