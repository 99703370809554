* + .btn {
  margin-left: 8px !important; }

.icon-btn {
  min-width: 32px; }

.icon-btn--dark svg {
  color: #e8eef2; }

.btn.btn--warning {
  color: #fff;
  background-color: #ed6f19; }
  .btn.btn--warning:focus {
    color: #fff;
    background-color: #ed6f19;
    outline: none;
    box-shadow: 0 0 10px #ed6f19; }
  .btn.btn--warning:active, .btn.btn--warning:focus:active, .btn.btn--warning:hover {
    border-color: transparent;
    color: #fff;
    background-color: #be5914; }

.btn.btn--system {
  color: #fff;
  background-color: #4c5358; }
  .btn.btn--system:focus {
    color: #fff;
    background-color: #4c5358;
    outline: none;
    box-shadow: 0 0 10px #4c5358; }
  .btn.btn--system:active, .btn.btn--system:focus:active, .btn.btn--system:hover {
    border-color: transparent;
    color: #fff;
    background-color: #3f4f5a; }
