.#{$menu-class-name} {
  // position: absolute; // Enable absolute postioning if necessary
  top: 0;
  left: 0;
  width: $menu-width;
  margin: $menu-top-margin 0 0;
  padding: $menu-padding;
  transform: translateY(#{-$menu-motion-offset});
  border-radius: $menu-border-radius;
  background-color: $menu-background-color;
  list-style: none;
  text-align: left;
  box-shadow: $menu-shadow;
  opacity: 0;
  z-index: $menu-z-index;
  pointer-events: none;
  @include transition-with-curve ($menu-motion-curve, $menu-motion-duration, transform, opacity);

  &--xsmall {
    width: $menu-xsmall-width;
  }

  &--small {
    width: $menu-small-width;
  }

  &--large {
    width: $menu-large-width;
  }

  &--from-bottom {
    transform: translateY($menu-motion-offset);
  }

  &--is-open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: inherit;
  }

  &--form {
    .form__item {
      margin-bottom: 0;
    }
  }

  &__breadcrumb {
    margin: 2px $spacing-16;
    text-align: left;
  }

  .clist__item {
    &:first-child {
      border-radius: $menu-border-radius $menu-border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $menu-border-radius $menu-border-radius;
    }

    > .menu {
      position: absolute;
      top: 0;
      left: 100%;
      opacity: 0;
    }

    &:hover > .menu {
      transform: translateY($menu-submenu-motion-offset);
      opacity: 1;
      pointer-events: all;

      &--form {
        transform: translateY($menu-submenu-motion-offset - 4px);
      }
    }
  }
}

.#{$menu-class-name}--limited {
  max-height: $limited-menu-max-height;
  overflow-y: auto;
}
