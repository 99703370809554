.side-drawer {
  min-height: calc(100vh - 40px);
  flex: none;
  border-right: 1px solid #d2d6d9;
  transition: all;
  transition-timing-function: cubic-bezier(0.3, 0.4, 0, 1);
  transition-duration: 0.21s;
  background-color: #fff; }
  .side-drawer ul.clist {
    margin: unset; }
  @media (max-width: 640px) {
    .side-drawer {
      position: fixed;
      overflow: auto;
      top: 54px;
      min-height: calc(100vh - 54px);
      max-height: calc(100vh - 54px);
      z-index: 5000;
      box-shadow: 10px 5px 9px 0px rgba(0, 0, 0, 0.36); } }
  .side-drawer.is-collapsed {
    visibility: hidden; }

@keyframes side-drawer-overlay-color {
  0% {
    background: transparent; }
  100% {
    background: #000; } }
