.#{$card-class-name} {
  border: $card-border;
  border-radius: $card-border-radius;
  outline: 0;
  background-color: $card-background-color;

  &:not([disabled]) {
    &:hover {
      border-color: $card-border-color-hover;
    }

    &:focus {
      border-color: $card-border-color-hover;
      box-shadow: $button-shadow;
    }
  }

  &[disabled] {
    * {
      pointer-events: none;
    }

    .#{$card-class-name}__title,
    .#{$card-class-name}__subtitle,
    .#{$card-class-name}__desc {
      color: $card-text-color-disabled;
    }   
  }

  &__title {
    display: flex;
    font-size: $card-title-font-size;

    &-wrapper {
      margin-top: $card-title-wrapper-margin-top;
      padding: $card-title-wrapper-padding;
    }

    h4 {
      margin: 0;
    }
  }

  &__subtitle {
    color: $card-subtitle-color;
    font-size: $card-subtitle-font-size;
  }

  &__image {
    margin-bottom: $card-image-margin-bottom;

    .#{$card-class-name}[disabled] & {
      opacity: .3;
    }

    img, 
    svg {
      display: block;
      width: $card-image-width;
      height: auto;
    }
  }

  &__desc {
    padding: $card-desc-padding;
  }

  &__footer {
    display: flex;
    padding: $card-footer-padding;

    .btn {
      margin: 0;
    }
  }

  &__toolbar {
    display: flex;
    margin-right: $card-toolbar-margin-right;
    margin-left: auto;

    .#{$card-class-name}__title & {
      margin-top: $card-toolbar-title-margin-top;
    }
  }
}