.main-container__wrapper .main-container__wrapper {
  max-height: 100%;
  min-height: 100%; }

.main-container__wrapper {
  display: flex;
  height: auto;
  max-height: 100vh;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden; }
  .main-container__wrapper .main-container__body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto; }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      .main-container__wrapper .main-container__body {
        flex: 1; } }
    @media (max-width: 959px) {
      .main-container__wrapper .main-container__body {
        max-height: calc(100vh - 54px); } }
    @media (min-width: 960px) {
      .main-container__wrapper .main-container__body {
        max-height: calc(100vh - 40px); } }
    .main-container__wrapper .main-container__body .main-container__main-section-content {
      display: initial; }
    @media (max-width: 600px) {
      .main-container__wrapper .main-container__body .main-container__main-section :not(.main-footer)* .flex-container {
        display: block !important; } }
  .main-container__wrapper .scrollbar-container.ps > .main-container__main-section,
  .main-container__wrapper .Pane > .main-container__main-section {
    height: 100%;
    min-height: 100%; }
  .main-container__wrapper .main-container__main-section {
    display: flex;
    width: 100%;
    max-height: 100%;
    flex-direction: column;
    flex-grow: 1; }
    .main-container__wrapper .main-container__main-section > .scrollbar-container.ps, .main-container__wrapper .main-container__main-section-content {
      position: relative;
      flex: 1 1 auto;
      overflow: auto; }
    .main-container__wrapper .main-container__main-section-content {
      min-height: 100%; }
  .main-container__wrapper .split-container.pane1-collapsed > .Pane2 {
    width: 100%; }
  .main-container__wrapper .split-container.pane1-collapsed > .Pane1 {
    width: 0%; }
  .main-container__wrapper .split-container.pane2-collapsed > .Pane1 {
    width: 100%; }
  .main-container__wrapper .split-container.pane2-collapsed > .Pane2 {
    width: 0%; }
  .main-container__wrapper .split-container.width-transition .Pane1 {
    transition: none; }
  .main-container__wrapper .split-container .Pane1 {
    transition: all;
    transition-timing-function: cubic-bezier(0.3, 0.4, 0, 1);
    transition-duration: .21s; }
  .main-container__wrapper .ps {
    overflow: auto !important;
    scrollbar-width: none; }
    .main-container__wrapper .ps::-webkit-scrollbar {
      width: 0px; }
  .main-container__wrapper .tabs--topbar-mobile .ps, .main-container__wrapper .tabs--topbar-desktop .ps, .main-container__wrapper .tab-bar-bottom .ps {
    overflow: hidden !important; }
  .main-container__wrapper .tab-bar-bottom {
    border-top: 1px solid #d2d6d9; }
    .main-container__wrapper .tab-bar-bottom .tabs {
      margin-top: 0px;
      border: none; }
    .main-container__wrapper .tab-bar-bottom .scrollbar-container {
      padding-right: 0px; }

.main-container .scrollbar-container.ps {
  display: inherit; }
