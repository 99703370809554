.emptystate__img {
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./images/search-not-found.svg");
  background-size: contain;
  margin: 32px; }

.emptystate__title {
  padding: 0; }
  .emptystate__title div {
    color: #4C5358;
    line-height: 28px;
    font-size: 20px;
    text-align: center;
    margin: 0; }

.emptystate__subtitle {
  padding: 0; }
  .emptystate__subtitle div {
    color: #6c777c;
    text-align: center;
    font-size: 14px; }
