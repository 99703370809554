.#{$message-bar-class-name} {
  display: flex;
  padding: $message-bar-padding;

  &--error {
    background-color: $message-bar-error-background;
    color: $message-bar-error-color;
  }

  &--warning {
    border-bottom: $message-bar-warning-border-bottom;
    background-color: $message-bar-warning-background-color;
  }

  &__icon  {
    margin-right: $message-bar-icon-margin-right;

    .#{$fa-icon-selector} {
      .#{$message-bar-class-name}--warning & {
        color: $message-bar-warning-icon-color;
      }

      .#{$message-bar-class-name}--error & {
        color: $message-bar-error-icon-color;
      }
    }
  }

  &__title {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content {
    flex: 1;
    min-width: 0;
  }

  &__desc {
    display: flex;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__close .icon-btn {
    width: $message-bar-close-icon-button-size;
    height: $message-bar-close-icon-button-size;
    margin-right: -$message-bar-close-icon-margin-left/4;
    margin-left: $message-bar-close-icon-margin-left;
    float: right;
    background-repeat: no-repeat;
    background-position: center;
    background-size: $message-bar-close-icon-size;

    .#{$message-bar-class-name}--warning & {
      @include close-icon($message-bar-warning-close-icon-color);
    }

    .#{$message-bar-class-name}--error & {
      @include close-icon($message-bar-error-close-icon-color);
    }
  }

  &__read-more {
    margin-left: $message-bar-read-more-margin-left;
    white-space: nowrap;
  }
}