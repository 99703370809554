.main-footer {
  display: flex;
  height: 60px;
  bottom: 0;
  min-height: 60px;
  max-height: 60px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #d2d6d9;
  overflow: visible; }
  .main-footer .btn, .main-footer .icon-btn {
    height: 44px;
    min-height: 44px;
    min-width: 44px;
    max-height: 44px;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 0px; }
  @media (max-width: 319px) {
    .main-footer {
      padding-right: .5rem;
      padding-left: .5rem; } }
  @media (min-width: 320px) and (max-width: 959px) {
    .main-footer {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media (min-width: 960px) and (max-width: 1259px) {
    .main-footer {
      padding-right: 1.5rem;
      padding-left: 1.5rem; } }
  @media (min-width: 960px) {
    .main-footer {
      height: 48px;
      min-height: 48px;
      max-height: 48px; }
      .main-footer .btn, .main-footer .icon-btn {
        height: 32px;
        min-height: 32px;
        min-width: 32px;
        max-height: 32px;
        line-height: 32px; } }
  @media (min-width: 1260px) {
    .main-footer {
      padding-right: 2rem;
      padding-left: 2rem; } }
  .main-footer > .flex-container {
    display: flex !important;
    height: 100%;
    max-width: 100%;
    max-height: 60px;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    overflow: visible; }
    @media (min-width: 960px) {
      .main-footer > .flex-container {
        max-height: 48px; } }
    .main-footer > .flex-container > .icon-btn, .main-footer > .flex-container > svg {
      margin: 0 8px;
      padding: 0; }
    .main-footer > .flex-container .display-field__wrapper {
      margin: 0;
      padding: 0 16px; }
      .main-footer > .flex-container .display-field__wrapper * {
        margin: 0;
        padding: 0; }
