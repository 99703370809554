.#{$prefix}clist {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: $clist-item-background-color;

  &__item {
    $self: &;
    display: flex;
    position: relative;
    align-items: center;
    background-color: $clist-item-background-color;
    line-height: $clist-item-line-height;

    &--divider {
      border-bottom: $clist-item-divider;

      + button:not(.btn--link) {
        margin: $spacing-12 $spacing-16;
      }

      + .btn--link {
        margin: 2px $spacing-16;
      }

      &-section {
        padding-bottom: $clist-item-divider-spacing !important;

        &:not(:last-child) {
          margin-bottom: $clist-item-divider-spacing !important;
        }
      }
    }

    &--bar-alert,
    &--bar-warning,
    &--bar-success {
      &:before {
        display: block;
        position: absolute;
        top: $clist-item-bar-y-offset;
        bottom: $clist-item-bar-y-offset;
        left: $clist-item-bar-left-offset;
        width: $clist-item-bar-width;
        border-radius: $clist-item-bar-border-radius;
        content: '';
      }
    }

    &--bar-alert:before {
      background-color: $color-alert;
    }

    &--bar-warning:before {
      background-color: $color-warning;
    }

    &--bar-success:before {
      background-color: $color-success;
    }

    &--on-drag {
      box-shadow: $clist-item-ondrag-shadow;
      opacity: $clist-item-ondrag-opacity;

      &:before {
        display: block;
        position: absolute;
        top: $clist-item-ondrag-top-offset;
        width: 100%;
        border-bottom: $clist-item-ondrag-top-border;
        content: '';
      }

      &:after {
        display: block;
        position: absolute;
        top: calc(50% - #{$clist-item-ondrag-icon-right});
        right: $clist-item-ondrag-icon-right;
        width: $clist-item-ondrag-icon-size;
        height: $clist-item-ondrag-icon-size;
        background-image: inline-svg($clist-item-ondrag-icon);
        content: '';
      }
    }

    > .#{$prefix}clist__item-link {
      display: flex;
      width: 100%;

      &:hover,
      &:focus {
        background-color: $clist-item-hover-bg;
      }

      &:focus {
        outline: none;
        box-shadow: $button-shadow;
        z-index: 1;
      }

      &:active,
      &.active,
      &.pressed {
        background-color: $clist-item-active-bg;
      }
    }

    &-inner-wrapper {
      min-width: 0;
      padding: $clist-item-spacing;

      #{ $self }--large & {
        padding: $clist-item-large-spacing;
      }

      #{ $self }--medium & {
        padding: $clist-item-medium-spacing;
      }

      #{ $self }--dot & {
        padding-left: 2 * $clist-item-medium-spacing;
      }

      #{ $self }--on-drag & {
        padding-right: 2 * $clist-item-medium-spacing;
      }
    }

    &-title {
      $t: &;
      position: relative;
      color: $clist-item-text-color;

      @at-root #{ $self } .#{$prefix}clist__item-link.active {
        > * > #{ $t } {
          font-weight: $font-weight-bold;
        }
      }

      #{ $self }--dot & {
        &:before {
          display: block;
          position: absolute;
          top: 6px;
          left: -16px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $clist-item-dot-color;
          content: '';
        }
      }
    }

    &-subtitle {
      color: $clist-item-subtext-color;
      font-size: $clist-item-subtext-font-size;
    }

    &-date {
      min-width: $clist-item-date-min-width;
      margin: $clist-item-spacing;
      color: $color-black;
      text-align: center;

      #{ $self }--large & {
        margin: $clist-item-large-spacing;
      }

      #{ $self }--medium & {
        margin: $clist-item-medium-spacing;
      }

      &-day {
        font-size: $clist-item-day-font-size;
        font-weight: $font-weight-bold;
        line-height: $clist-item-day-line-height;
      }

      &-month {
        color: $clist-item-month-color;
        font-size: $clist-item-month-font-size;
        line-height: $clist-item-month-line-height;
        text-transform: $clist-item-month-style;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      margin: $clist-item-spacing;

      &--avatar {
        min-width: $clist-item-icon-min-width;

        .#{$fa-icon-selector} {
          width: $clist-item-icon-size;
          height: $clist-item-icon-size;
          margin: $clist-item-icon-margin;
          color: $clist-item-icon-avatar-color;
        }
      }

      &--action {
        .#{$fa-icon-selector} {
          color: $clist-item-icon-action-color;
        }
      }

      &--direct {
        .#{$fa-icon-selector} {
          color: $clist-item-icon-direct-color;
        }
      }

      #{ $self }--large & {
        margin: $clist-item-large-spacing;
      }

      #{ $self }--medium & {
        margin: $clist-item-medium-spacing;
      }
    }
  }

  &-group__title {
    padding: $clist-item-medium-spacing;
    background-color: $color-white;
    color: $clist-group-title-color;
    font-size: $clist-group-title-font-size;
  }
}