﻿// Lists
$list-class-name: #{$prefix}list !default;
$list-margin: 16px 24px 16px 0 !default;
$list-padding-left: 2rem !default;
$list-color: $color-grey-120 !default;
$list-item-padding-left: px-to-rem(10px) !default;
$list-sub-padding-left: 2rem !default;

// List ordered
$ordrdlist-style-type: decimal !default;

// List unordered
$unrdlist-bullet-margin-left: 19px !default;
$unrdlist-top-negative: 14px !default;

// List inline
$inlnlist-item-margin-left: 16px !default;
$inlnlist-item-divider-width: 1px !default;
$inlnlist-item-divider-color: $color-grey-34 !default;

// Icon list
$iconlist-padding-left: px-to-rem(18px);
$iconlist-icon-margin-right: 8px !default;
