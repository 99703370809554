$menu-class-name: #{$prefix}menu !default;
$menu-width: 186px !default;
$menu-xsmall-width: 56px !default;
$menu-small-width: 124px !default;
$menu-large-width: 272px !default;
$menu-top-margin: 2px;
$menu-padding: px-to-rem(4px) 0;
$menu-background-color: $color-white !default;
$menu-border-radius: 4px !default;
$menu-shadow: 0 2px 8px 1px rgba($color-grey-120, .4) !default;
$menu-z-index: 400 !default;
$menu-motion-curve: smooth-elastic !default;
$menu-motion-duration: .26s !default;
$menu-motion-offset: 10px !default;
$menu-submenu-motion-offset: -4px !default;
$menu-z-elevation: 2 !default;
$limited-menu-max-height: 400px !default;
