$icon-btn-size: 32px;
$icon-btn-small-size: 24px;
$icon-btn-border-radius: 50%;
$icon-btn-color: $color-grey-unit4;
$icon-btn-shadow: $button-shadow;
$icon-btn-color-hover: $color-darkgrey-unit4;
$icon-btn-color-active: $color-black;

$icon-btn-border: 1px solid $button-border-color-default;
$icon-btn-border-border-radius: 4px;
$icon-btn-border-background-color: $button-background-default;
$icon-btn-border-hover: $button-border-color-default-hover;
$icon-btn-border-background-color-hover: $button-background-default-hover;
$icon-btn-border-background-color-active: $button-background-default-active;

$icon-btn-dark-color: $color-grey-blue-background;
$icon-btn-dark-color-hover: $color-white;
$icon-btn-dark-box-shadow: 0 0 10px $color-grey-blue-background;