// Checkbox
.#{$input-checkbox-class-name} {
  $click-area-size: $input-checkbox-click-area-size;
  $margin: px-to-rem(($input-checkbox-click-area-size - $input-checkbox-size) / 2);
  $box-selector: '+ label:before';
  $tick-selector: '+ label:after';

  + label {
    height: $click-area-size;
    margin-right: $input-checkbox-margin-right;
    padding-right: $input-checkbox-text-right-padding;
    padding-left: $input-checkbox-text-left-padding;
  }

  &:not(:disabled) {
    + label {
      cursor: pointer;
    }
  }

  #{$box-selector},
  #{$tick-selector} {
    display: block;
    position: absolute;
    content: '';
  }

  #{$box-selector} {
    top: 0;
    left: 0;
    width: px-to-rem($input-checkbox-size);
    height: px-to-rem($input-checkbox-size);
    transition: all .3s ease;
    border: $input-checkbox-border-size $input-checkbox-border-style $input-checkbox-border-color;
    background-color: $input-checkbox-background;
    transition-property: background-color, border-color;

    @at-root .input--small#{&} {
      width: $input-small-size;
      height: $input-small-size;
    }
  }

  #{$tick-selector} {
    top: $input-checkbox-tick-top;
    left: $input-checkbox-tick-left;
    width: $input-checkbox-tick-width;
    height: $input-checkbox-tick-height;
    transform: rotate(-52deg) rotateY(-22deg) rotateX(28deg);
    transition: opacity .2s ease;
    border: 2.5px solid $color-white;
    border-top: none;
    border-right: none;
    border-top-left-radius: 1px;
    border-bottom-right-radius: 1px;
    opacity: 0;
    transform-origin: left top;
    animation: tick-disappear-default .3s;

    @at-root .input--small#{&} {
      top: $input-checkbox-small-tick-top;
      left: $input-checkbox-small-tick-left;
      width: $input-checkbox-small-tick-width;
      height: $input-checkbox-small-tick-height;
      border-width: 2px;
      animation-name: tick-disappear-small !important;
    }
  }

  &:hover:not(:disabled) #{$box-selector} {
    border-color: $input-checkbox-hover-color;
    background-color: $input-checkbox-hover-background;
  }

  &:focus #{$box-selector} {
    border-color: $input-checkbox-focus-color;
    background-color: $input-checkbox-hover-background;
    box-shadow: $button-shadow;
  }

  &:active:not(:disabled) #{$box-selector} {
    border-color: $input-checkbox-active-color;
  }

  &:checked,
  &[aria-checked='mixed'] {
    #{$box-selector} {
      border-color: $input-checkbox-checked-background-color;
      background-color: $input-checkbox-checked-background-color;
    }

    #{$tick-selector} {
      opacity: 1;
      animation: tick-appear-default .3s;

      @at-root .input--small#{&} {
        animation-name: tick-appear-small !important;
      }
    }

    &:hover:not(:disabled) #{$box-selector},
    &:focus #{$box-selector} {
      background-color: $input-checkbox-hover-color;
    }
  }

  &[aria-checked='mixed'] #{$tick-selector} {
    top: 11px;
    width: 16px;
    height: 2px;
    transform: none;
    border-width: 2px;
    border-radius: 10px;
    animation: none;

    @at-root .input--small#{&} {
      top: 7px;
      width: 12px;
    }
  }

  &[disabled] {
    &:hover + label {
      color: inherit;
    }
  }
}

@include checkbox-tick-animation;
@include checkbox-tick-animation(small);
