﻿.#{$panel-class-name} {
  display: flex;
  flex-direction: column;  
  border: $panel-border;

  border-radius: $panel-border-radius;
  background-color: $panel-background-color;

  &__header {
    display: flex;
    flex-direction: column;
    margin-top: px-to-rem($panel-vertical-spacing);
    padding-bottom: px-to-rem($panel-vertical-spacing);
    border-bottom: $panel-border;

    &.#{$panel-class-name}__header--tabs { 
      margin-top: 0;

      .tabs {
        margin-top: 0;  
        border-radius: $panel-border-radius;
      }
    }

    >.tabs {
      margin-top: $panel-tabs-margin-top; 
      margin-bottom: -($panel-tabs-vertical-spacing + $panel-border-width);
    }

    >div,
    ul {
      padding-right: px-to-rem($panel-horizontal-spacing);
      padding-left: px-to-rem($panel-horizontal-spacing);
    }
  }

  &__title {
    display: flex;
    align-items: start;

    h2 {
      margin-bottom: 0;
    }
  }

  &__subheader {
    margin-top: $panel-subheader-margin-top;
    color: $panel-subheader-color;
    font-size: px-to-rem($font-size-body);
    font-weight: $font-weight-normal;
    line-height: $line-height-base;
  }

  &__action {
    display: flex;
    align-items: center;
    min-height: $panel-action-min-height;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: px-to-rem($panel-vertical-spacing) px-to-rem($panel-horizontal-spacing);

    &.#{$panel-class-name}__content--subpanel {
      flex-direction: row;
    }

    h4 {
      margin-bottom: $panel-subpanel-subheading-margin-bottom;
    }

    p {
      margin: 0 0 $panel-content-text-margin-bottom;
      color: $panel-content-text-color;

      &:last-child {
        margin: 0;
      }
    }

    .#{$panel-class-name}__content-assistive-text {
      margin-bottom: $panel-content-assistive-text-margin-bottom;
      color: $panel-content-assistive-text-color;
      font-size: px-to-rem($panel-content-assistive-text-font-size);
      line-height: $panel-content-assistive-text-line-height;
    }

    .#{$panel-class-name}__subpanel {      
      padding: px-to-rem($panel-subpanel-padding);
      border: $panel-border;
      border-radius: $panel-subpanel-border-radius;
    }
  }

  &__footer {    
    display: flex;
    padding: px-to-rem($panel-vertical-spacing) px-to-rem($panel-horizontal-spacing);
    border-top: $panel-border;

    .btn {      
      margin: $panel-footer-btn-margin;
    }
  }
}
