// Hyperlink settings

// Hyperlink class name
$link-class-name: #{$prefix}link !default;

// Color
$hyperlink-color: $color-blue !default;

// Hover color
$hyperlink-color-hover: mix($hyperlink-color, $color-black, 80%) !default;

// Active color
$hyperlink-color-active: mix($hyperlink-color, $color-black, 60%) !default;

// Visited color
$hyperlink-color-visited: $color-scheme-darkpurple2 !default;
