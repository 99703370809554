.modal__wrap.is--opened {
  z-index: 4000;
  cursor: grab; }
  .modal__wrap.is--opened:active {
    cursor: grabbing; }
  .modal__wrap.is--opened.modal-inside-container {
    position: relative;
    width: 100%;
    height: 100%; }
  .modal__wrap.is--opened .cancel__draggable {
    cursor: default; }
  .modal__wrap.is--opened .modal__body {
    z-index: 4000;
    animation: modal-appears 0.23s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .modal__wrap.is--opened .modal__body .modal {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 480px;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 rgba(76, 83, 88, 0.4); }
      @media (max-width: 960px) {
        .modal__wrap.is--opened .modal__body .modal {
          max-height: 75vh;
          min-width: 288px; } }
      @media (min-width: 961px) {
        .modal__wrap.is--opened .modal__body .modal {
          max-height: 50vh;
          min-width: 350px; } }
      .modal__wrap.is--opened .modal__body .modal .modal__title {
        width: auto; }
      .modal__wrap.is--opened .modal__body .modal .modal__message {
        overflow: auto; }
        .modal__wrap.is--opened .modal__body .modal .modal__message .display-field__wrapper {
          padding: 8px 0; }
      .modal__wrap.is--opened .modal__body .modal .modal__footer {
        justify-content: flex-end; }
      .modal__wrap.is--opened .modal__body .modal .modal__icon {
        height: 24px;
        width: 24px;
        margin: 8px 16px 8px 0px; }
      .modal__wrap.is--opened .modal__body .modal .modal__close {
        height: 32px;
        width: 32px;
        background-image: url("./images/times-regular.svg");
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer; }

@keyframes modal-appears {
  0% {
    margin-top: 50px;
    opacity: 0; }
  100% {
    margin-top: 0px;
    opacity: 1; } }
