.renderer-container .main-container__wrapper {
  max-height: none;
  min-height: none; }
  .renderer-container .main-container__wrapper .topbar, .renderer-container .main-container__wrapper .main-footer {
    position: relative;
    left: auto;
    right: auto;
    background-color: #fff;
    border: 1px solid #d2d6d9;
    z-index: 1900; }
  .renderer-container .main-container__wrapper .topbar {
    top: auto;
    margin: 20px 8px 0 8px;
    border-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .renderer-container .main-container__wrapper .topbar .topbar__logo {
      display: none; }
    .renderer-container .main-container__wrapper .topbar .topbar__brand-name {
      color: #000;
      margin: 0 !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.4; }

.renderer-container .main-footer {
  margin: 8px;
  bottom: auto;
  border-radius: 4px; }

.renderer-container .main-container__main-section-header {
  margin: 0 8px;
  border: 1px solid #d2d6d9;
  border-top: 0; }
  .renderer-container .main-container__main-section-header .header {
    padding: 1rem 0 1.5rem;
    border: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
