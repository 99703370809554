.#{$content-slider-class} {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--vertical {
    flex-direction: row;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $content-slider-wrapper-margin-bottom;

    .#{$content-slider-class}--vertical & {
      margin-bottom: 0;

      &:nth-child(2) {
        flex-direction: column;
        margin-left: $content-slider-nav-margin;
      }
    }
  }

  &__content {
    display: flex;
    max-width: $content-slider-content-max-width;
    max-height: $content-slider-content-max-height;
    overflow: hidden;

    .#{$content-slider-class}--vertical & {
      flex-direction: column;

    }

    &-item {
      min-width: $content-slider-content-max-width;
      min-height: $content-slider-content-max-height;
      margin: 0 $content-slider-content-item-margin 0 0;

      &--demo {
        border: 2px dashed $color-grey-34;
      }
    }
  }

  &__nav {
    &--prev {
      margin-right: $content-slider-nav-margin;

      .#{$content-slider-class}--vertical & {
        margin-right: auto;
        margin-bottom: $content-slider-nav-margin;
        transform: rotate(90deg);
      }
    }

    &--next {
      margin-left: $content-slider-nav-margin;

      .#{$content-slider-class}--vertical & {
        margin-top: $content-slider-nav-margin;
        margin-left: auto;
        transform: rotate(90deg);
      }
    }
  }

  &__bullets {
    display: flex;
    align-items: center;
    height: $content-slider-bullets-height;

    .#{$content-slider-class}--vertical & {
      flex-direction: column;
      height: auto;
    }

    &-item {
      width: $content-slider-bullets-size;
      height: $content-slider-bullets-size;
      border-radius: 50%;
      background-color: $content-slider-bullets-color;
      cursor: pointer;

      .#{$content-slider-class}--vertical & {
        &:not(:last-child) {
          margin-right: auto;
          margin-bottom: $content-slider-bullets-margin;
        }
      }

      &:not(:last-child) {
        margin-right: $content-slider-bullets-margin;
      }

      &:hover {
        background-color: $content-slider-bullets-hover-color;
      }

      &--active {
        background-color: $content-slider-bullets-active-color;
      }
    }
  }

  &__pagination {
    position: relative;
    min-width: $content-slider-pagination-min-width;
    color: $content-slider-pagination-color;
    font-weight: $content-slider-pagination-font-weight;
    text-align: center;

    [contenteditable='true'] {
      &:focus {
        top: -4px;
      }
    }
  }
}