.display-field__wrapper {
  padding: 8px; }
  .display-field__wrapper.no-padding {
    padding: 0; }
    .display-field__wrapper.no-padding div {
      padding: 0;
      margin: 0; }
  .display-field__wrapper p {
    display: inline-block;
    margin: 0; }
