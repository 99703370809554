$color-white: #fff;
$color-black: #000;

$color-grey: #7c878e;
$color-grey-8: mix($color-grey, $color-white, 8);  // #f5f6f6
$color-grey-12: mix($color-grey, $color-white, 12); // #eff0f1
$color-grey-18: mix($color-grey, $color-white, 18); // #e7e9ea
$color-grey-34: mix($color-grey, $color-white, 34); // #d2d6d8
$color-grey-65: mix($color-grey, $color-white, 65); // #a9b1b5
$color-grey-110: #6c777c;
$color-grey-120: #4c5358;

$color-blue: #0d78aa;
$color-lightblueselect: #b7e9f8;
$color-lightblue: #dbf4fb;
$color-paleblue: #edf9fd;
$color-grey-blue-background: #e8eef2;
$color-grey-blue-pale: #f2f7fa;

$color-grey-unit4: #4a5c69;
$color-darkgrey-unit4: #3f4f5a;

$color-alert: #c10606;
$color-alert-light: #f69390;
$color-alert-pale: #fcd0cc;
$color-alert-20: #9a0505;
$color-alert-40: #740404;

$color-warning: #ed6f19 !default;
$color-warning-text: #c45500 !default;
$color-warning-light: #ffbe64 !default;
$color-warning-pale: #fff5d6 !default;

$color-success: #388700 !default;
$color-success-light: #afd189 !default;
$color-success-pale: #dbefb9 !default;

$color-info: $color-blue !default;
$color-info-light: #90cdea !default;
$color-info-pale: #d9edf7 !default;

$color-system: $color-grey-120 !default;
$color-system-light: $color-grey-34 !default;
$color-system-pale: $color-grey-18 !default;

$color-scheme-u4green: #bfda3a !default;
$color-scheme-u4darkgreen: #afc836 !default;
$color-scheme-u4green-10: mix($color-scheme-u4green, $color-white, 10%) !default; // #f9fbeb
$color-scheme-u4green-20: mix($color-scheme-u4green, $color-white, 20%) !default; // #f2f8d8;
$color-scheme-u4green-40: mix($color-scheme-u4green, $color-white, 40%) !default; // #e5f0b0;
$color-scheme-u4green-70: mix($color-scheme-u4green, $color-white, 70%) !default; // #d2e575;

$color-scheme-u4blue: #4ac7ed !default;
$color-scheme-u4darkblue: #38add1 !default;
$color-scheme-u4blue-10: mix($color-scheme-u4blue, $color-white, 10%) !default; // #edf9fd
$color-scheme-u4blue-20: mix($color-scheme-u4blue, $color-white, 20%) !default; // #dbf4fb
$color-scheme-u4blue-40: mix($color-scheme-u4blue, $color-white, 40%) !default; // #b7e9f8
$color-scheme-u4blue-70: mix($color-scheme-u4blue, $color-white, 70%) !default; // #80d8f2

$color-scheme-u4orange: #f68d2e !default;
$color-scheme-u4darkorange: #e87d19 !default;
$color-scheme-u4orange-10: mix($color-scheme-u4orange, $color-white, 10%) !default; // #fef4ea
$color-scheme-u4orange-20: mix($color-scheme-u4orange, $color-white, 20%) !default; // #fde8d5
$color-scheme-u4orange-40: mix($color-scheme-u4orange, $color-white, 40%) !default; // #fbd1ab
$color-scheme-u4orange-70: mix($color-scheme-u4orange, $color-white, 70%) !default; // #f9af6d

$color-scheme-blue: #4296f5 !default;
$color-scheme-darkblue: #3a83d6 !default;
$color-scheme-blue-10: mix($color-scheme-blue, $color-white, 10%) !default; // #ecf5fe
$color-scheme-blue-20: mix($color-scheme-blue, $color-white, 20%) !default; // #d9eafd
$color-scheme-blue-40: mix($color-scheme-blue, $color-white, 40%) !default; // #b3d5fb
$color-scheme-blue-70: mix($color-scheme-blue, $color-white, 70%) !default; // #7bb6f8

$color-scheme-violet: #d742f5 !default;
$color-scheme-darkviolet: #c13bdb !default;
$color-scheme-violet-10: mix($color-scheme-violet, $color-white, 10%) !default; // #fbecfe;
$color-scheme-violet-20: mix($color-scheme-violet, $color-white, 20%) !default; // #f7d9fd;
$color-scheme-violet-40: mix($color-scheme-violet, $color-white, 40%) !default; // #efb3fb;
$color-scheme-violet-70: mix($color-scheme-violet, $color-white, 70%) !default; // #e37bf8;

$color-scheme-aqua: #19e2a2 !default;
$color-scheme-darkaqua: #17d196 !default;
$color-scheme-aqua-10: mix($color-scheme-aqua, $color-white, 10%) !default; // #e8fcf6;
$color-scheme-aqua-20: mix($color-scheme-aqua, $color-white, 20%) !default; // #d1f9ec;
$color-scheme-aqua-40: mix($color-scheme-aqua, $color-white, 40%) !default; // #a3f3da;
$color-scheme-aqua-70: mix($color-scheme-aqua, $color-white, 70%) !default; // #5eebbe;

$color-scheme-pink: #ed51b4 !default;
$color-scheme-darkpink: #db4ba6 !default;
$color-scheme-pink-10: mix($color-scheme-pink, $color-white, 10%) !default; // #fdeef8;
$color-scheme-pink-20: mix($color-scheme-pink, $color-white, 20%) !default; // #fbdcf0;
$color-scheme-pink-40: mix($color-scheme-pink, $color-white, 40%) !default; // #f8b9e1;
$color-scheme-pink-70: mix($color-scheme-pink, $color-white, 70%) !default; // #f285cb;

$color-scheme-yellow: #facf4b !default;
$color-scheme-darkyellow: #ebbf3b !default;
$color-scheme-yellow-10: mix($color-scheme-yellow, $color-white, 10%) !default; // #fffaed;
$color-scheme-yellow-20: mix($color-scheme-yellow, $color-white, 20%) !default; // #fef5db;
$color-scheme-yellow-40: mix($color-scheme-yellow, $color-white, 40%) !default; // #fdecb7;
$color-scheme-yellow-70: mix($color-scheme-yellow, $color-white, 70%) !default; // #fcdd81;

$color-scheme-purple: #a04bfa !default;
$color-scheme-darkpurple: #8a3cde !default;
$color-scheme-purple-10: mix($color-scheme-purple, $color-white, 10%) !default; // #f6edff;
$color-scheme-purple-20: mix($color-scheme-purple, $color-white, 20%) !default; // #ecdbfe;
$color-scheme-purple-40: mix($color-scheme-purple, $color-white, 40%) !default; // #d9b7fd;
$color-scheme-purple-70: mix($color-scheme-purple, $color-white, 70%) !default; // #bc81fc;

$color-scheme-green: #75e05a !default;
$color-scheme-darkgreen: #6acc52 !default;
$color-scheme-green-10: mix($color-scheme-green, $color-white, 10%) !default; // #f1fcef;
$color-scheme-green-20: mix($color-scheme-green, $color-white, 20%) !default; // #e3f9de;
$color-scheme-green-40: mix($color-scheme-green, $color-white, 40%) !default; // #c8f3bd;
$color-scheme-green-70: mix($color-scheme-green, $color-white, 70%) !default; // #9ee98c;

$color-scheme-red: #fa4e4b !default;
$color-scheme-darkred: #e03f3d !default;
$color-scheme-red-10: mix($color-scheme-red, $color-white, 10%) !default; // #ffeded;
$color-scheme-red-20: mix($color-scheme-red, $color-white, 20%) !default; // #fedcdb;
$color-scheme-red-40: mix($color-scheme-red, $color-white, 40%) !default; // #fdb8b7;
$color-scheme-red-70: mix($color-scheme-red, $color-white, 70%) !default; // #fc8381;

$color-scheme-purple2: #704dfa !default;
$color-scheme-darkpurple2: #5c3cde !default;
$color-scheme-purple2-10: mix($color-scheme-purple2, $color-white, 10%) !default; // #f1edff;
$color-scheme-purple2-20: mix($color-scheme-purple2, $color-white, 20%) !default; // #e2dbfe;
$color-scheme-purple2-40: mix($color-scheme-purple2, $color-white, 40%) !default; // #c6b8fd;
$color-scheme-purple2-70: mix($color-scheme-purple2, $color-white, 70%) !default; // #9b82fc;

$color-btn-happy-hover: mix($color-blue, $color-black, 80%) !default; // #0a6088;
$color-btn-happy-press: mix($color-blue, $color-black, 60%) !default; // #074866;
