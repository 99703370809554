.panel .panel__content {
  flex: 1 1 auto; }

.panel .panel__header--with--tabs {
  padding-bottom: 0px;
  border-bottom: none; }

.panel--noborder {
  border: 0; }
  .panel--noborder > .panel__header {
    border-bottom: 0; }

.flex-panel {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 8px); }
  .flex-panel.align-self-auto {
    align-self: auto; }
  .flex-panel.align-self-flex-start {
    align-self: flex-start; }
  .flex-panel.align-self-flex-end {
    align-self: flex-end; }
  .flex-panel.align-self-center {
    align-self: center; }
  .flex-panel.align-self-baseline {
    align-self: baseline; }
  .flex-panel.align-self-stretch {
    align-self: stretch; }
  .flex-panel > .display-block {
    display: block; }

.flex-panel-content {
  display: flex; }
  .flex-panel-content > .flex-panel {
    flex: auto;
    margin: 8px; }
  .flex-panel-content > .flex-panel {
    margin: 0; }
  .flex-panel-content.flex-panel-content--row {
    flex-direction: row; }
    .flex-panel-content.flex-panel-content--row.reverse {
      flex-direction: row-reverse; }
  .flex-panel-content.flex-panel-content--col {
    flex-direction: column; }
    .flex-panel-content.flex-panel-content--col.reverse {
      flex-direction: column-reverse; }
  .flex-panel-content.align-items-flex-start {
    align-items: flex-start; }
  .flex-panel-content.align-items-flex-end {
    align-items: flex-end; }
  .flex-panel-content.align-items-center {
    align-items: center; }
  .flex-panel-content.align-items-baseline {
    align-items: baseline; }
  .flex-panel-content.align-items-stretch {
    align-items: stretch; }
  .flex-panel-content.align-content-flex-start {
    align-content: flex-start; }
  .flex-panel-content.align-content-flex-end {
    align-content: flex-end; }
  .flex-panel-content.align-content-center {
    align-content: center; }
  .flex-panel-content.align-content-space-between {
    align-content: space-between; }
  .flex-panel-content.align-content-space-around {
    align-content: space-around; }
  .flex-panel-content.align-content-stretch {
    align-items: stretch; }
  .flex-panel-content.justify-content-flex-start {
    justify-content: flex-start; }
  .flex-panel-content.justify-content-flex-end {
    justify-content: flex-end; }
  .flex-panel-content.justify-content-center {
    justify-content: center; }
  .flex-panel-content.justify-content-space-between {
    justify-content: space-between; }
  .flex-panel-content.justify-content-space-around {
    justify-content: space-around; }
  .flex-panel-content.justify-content-space-evenly {
    justify-content: space-evenly; }
