.form__item .input.input--combo {
  padding: 0 !important;
  margin: 0 !important; }
  .form__item .input.input--combo .select {
    border: 0; }
    .form__item .input.input--combo .select + .label {
      order: -1; }
    .form__item .input.input--combo .select__multi-value__remove {
      cursor: pointer; }
    .form__item .input.input--combo .select__control {
      display: flex;
      min-height: 33px;
      border-radius: 2px;
      border-color: #aab1b6;
      border: 0 !important; }
      .form__item .input.input--combo .select__control .select__value-container {
        width: 0px; }
        .form__item .input.input--combo .select__control .select__value-container .select__single-value {
          position: absolute;
          max-width: calc(100% - 40px);
          overflow: hidden; }
      .form__item .input.input--combo .select__control--menu-is-open .select__control {
        border-radius: 2px;
        border: 1px solid #0d78aa;
        box-shadow: 0 0 10px #0d78aa; }
        .form__item .input.input--combo .select__control--menu-is-open .select__control--menu-is-open {
          border-radius: 2px;
          border: 1px solid #0d78aa;
          box-shadow: 0 0 10px #0d78aa; }
    .form__item .input.input--combo .select__placeholder {
      max-width: calc(100% - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .form__item .input.input--combo .select .select.has-value.select--single > .select__control .select-value .select-value-label,
    .form__item .input.input--combo .select .select.has-value.is-pseudo-focused.select--single > .select__control .select-value .select-value-label {
      color: #000; }
    .form__item .input.input--combo .select .select__menu {
      border-radius: 0;
      box-shadow: 0 2px 8px 1px rgba(63, 79, 90, 0.4);
      z-index: 10; }
      .form__item .input.input--combo .select .select__menu .select__option {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .form__item .input.input--combo .select .select__menu .select__option--is-focused {
        background-color: #edf9fd; }
        .form__item .input.input--combo .select .select__menu .select__option--is-focused:active {
          background-color: #dbf4fb;
          box-shadow: 0 0 10px #0d78aa;
          z-index: 1; }
      .form__item .input.input--combo .select .select__menu .select__option--is-selected {
        background-color: #dbf4fb;
        outline: 0;
        font-weight: bold;
        color: #333; }
    .form__item .input.input--combo .select .select__indicators {
      cursor: pointer;
      height: 33px; }
      .form__item .input.input--combo .select .select__indicators .select__dropdown-indicator {
        display: inline-block;
        position: relative;
        height: 0;
        width: 0;
        margin-right: 8px;
        margin-top: 6px;
        padding: 0;
        border-color: #4c5358 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px; }
        .form__item .input.input--combo .select .select__indicators .select__dropdown-indicator svg {
          display: none; }
      .form__item .input.input--combo .select .select__indicators:hover, .form__item .input.input--combo .select .select__indicators:focus {
        border-color: #4c5358 transparent transparent; }
    .form__item .input.input--combo .select .select__control--menu-is-open > .select__indicators .select__indicator {
      top: -2px;
      border-color: transparent transparent #4c5358;
      border-width: 0 5px 5px; }
      .form__item .input.input--combo .select .select__control--menu-is-open > .select__indicators .select__indicator:hover, .form__item .input.input--combo .select .select__control--menu-is-open > .select__indicators .select__indicator:focus {
        border-color: transparent transparent #4c5358; }
  .form__item .input.input--combo .select__control--is-focused:not(.is-open) {
    border: 1px solid #0d78aa;
    box-shadow: 0 0 10px #0d78aa; }

.select-menu-outer {
  z-index: 999; }
