﻿// Gives styles to input range track using the variables defined in Settings.
@mixin input-range-track() {
  height: $input-range-track-height;
  border: none;
  background: linear-gradient($input-range-background-fill, $input-range-background-fill) no-repeat $input-range-background;
  background-color: $input-range-background;
  background-size: 0% 100%;
}

// Gives styles to the input range thumb button using the variables defined in Settings.
@mixin input-range-thumb() {
  @include border(solid, $color-grey, 1px);
  @include transition-with-curve ($input-range-transition-curve, $input-range-transition-duration, transform);
  width: $input-range-thumb-size;
  height: $input-range-thumb-size;
  border-radius: $input-range-thumb-radius;
  background: $input-range-thumb-background;
}

// Gives styles to the input range thumb button when range is active
@mixin input-range-thumb-active() {
  transform: scale(1.2);
}

// Gives styles to the input range thumb button when range is focus
@mixin input-range-thumb-focus() {
  box-shadow: 0 0 $input-focus-outerglow-distance $input-focus-outerglow-color;
}

// Radio buttons: positioning and size for inner elements.
@mixin input-radio-center-element($size) {
  top: 0;
  left: 0;
  width: px-to-rem($size);
  height: px-to-rem($size);
}

// Checkbox tick animation
@mixin checkbox-tick-animation($size: default) {
  @keyframes tick-appear-#{$size} {
    0% {
      width: 0;
      height: 0;
    }

    25% {
      width: 0;
      height: map-get($checkbox-tick-height , $size);
    }

    50% {
      width: map-get($checkbox-tick-width , $size);
      height: map-get($checkbox-tick-height , $size);
    }
  }

  @keyframes tick-disappear-#{$size} {
    0% {
      width: map-get($checkbox-tick-width , $size);
      height: map-get($checkbox-tick-height , $size);
    }

    25% {
      width: 0;
      height: map-get($checkbox-tick-height , $size);
    }

    50% {
      width: 0;
      height: 0;
    }
  }
}
