﻿// Taken from: https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_font-face.scss
// Order of the includes matters, and it is: normal, bold, italic, bold+italic.

// Font-face - Mixin that includes .woff and .ttf sources automatically.
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$file-path}.woff') format('woff'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.ttf') format('truetype');
  }
}
