.ag-grid__wrapper {
  position: relative;
  border-collapse: collapse;
  max-width: 100%; }
  .ag-grid__wrapper .btn {
    margin-left: 24px; }
  .ag-grid__wrapper .ag-root-wrapper-body {
    width: auto;
    min-width: 250px;
    border: 0;
    outline: 1px solid #d2d6d9; }
  .ag-grid__wrapper .ag-root-wrapper, .ag-grid__wrapper .ag-root, .ag-grid__wrapper .ag-root.ag-layout-auto-height, .ag-grid__wrapper .ag-root.ag-layout-normal {
    overflow: visible; }
  .ag-grid__wrapper .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-grid__wrapper .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-height: 36px; }
  .ag-grid__wrapper .ag-header {
    height: 36px;
    min-height: 36px;
    color: #4c5358;
    border-bottom: 1px solid #d2d6d9;
    font-size: .75rem;
    line-height: 1.66667;
    font-weight: 400;
    text-align: left; }
  .ag-grid__wrapper .ag-header-cell {
    background-color: #fff;
    text-transform: capitalize; }
    .ag-grid__wrapper .ag-header-cell:after {
      position: absolute;
      right: 0px;
      top: 0;
      width: 1px;
      height: 20px;
      margin-top: 8px;
      background-color: #d2d6d9;
      content: ''; }
    .ag-grid__wrapper .ag-header-cell:first-child {
      padding-left: 16px; }
    .ag-grid__wrapper .ag-header-cell:last-child {
      padding-right: 16px; }
      .ag-grid__wrapper .ag-header-cell:last-child::after {
        border-right: transparent;
        background-color: transparent; }
    .ag-grid__wrapper .ag-header-cell .ag-header-cell-label {
      margin-top: 8px; }
    .ag-grid__wrapper .ag-header-cell .ag-header-cell-text {
      width: 100%;
      padding: 8px;
      color: #4c5358;
      font-size: .75rem;
      font-weight: 400;
      line-height: 1.66667;
      text-align: left; }
  .ag-grid__wrapper .ag-row {
    display: table-row;
    height: 36px;
    border-color: inherit;
    vertical-align: inherit; }
    .ag-grid__wrapper .ag-row:not(:last-child) {
      border-bottom: 1px solid #e7e9eb; }
    .ag-grid__wrapper .ag-row:hover {
      background-color: #edf9fd; }
    .ag-grid__wrapper .ag-row .ag-cell {
      display: table-cell;
      padding-left: .5rem;
      font-size: .875rem;
      line-height: 2.4;
      word-break: break-all; }
      .ag-grid__wrapper .ag-row .ag-cell:first-child {
        padding-left: 24px; }
      .ag-grid__wrapper .ag-row .ag-cell:last-child {
        padding-right: 24px; }
  .ag-grid__wrapper .ag-row-editing .ag-cell {
    overflow: visible; }
  .ag-grid__wrapper .ag-row-editing .ag-cell.ag-cell-not-inline-editing {
    overflow: hidden; }
  .ag-grid__wrapper .ag-row-even {
    background-color: #f2f7fa; }
  .ag-grid__wrapper .ag-row-odd {
    background-color: #fff; }
  .ag-grid__wrapper .ag-row-focus {
    background-color: #dbf4fb; }
    .ag-grid__wrapper .ag-row-focus:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      border-left: 2px solid #0d78aa;
      content: ''; }
  .ag-grid__wrapper .ag-cell-inline-editing {
    position: absolute;
    padding-left: 0;
    outline: none;
    border-radius: 0; }
    .ag-grid__wrapper .ag-cell-inline-editing:focus {
      border: 1px solid #0d78aa;
      outline: none;
      box-shadow: 0 0 10px #0d78aa; }
  .ag-grid__wrapper .rdg-selected {
    border: 1px solid #0d78aa;
    opacity: .5; }
    .ag-grid__wrapper .rdg-selected:focus {
      opacity: 1; }
    .ag-grid__wrapper .rdg-selected .drag-handle {
      background: #0d78aa; }
  .ag-grid__wrapper .pagination-wrapper {
    margin: 8px 24px; }
