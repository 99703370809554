// Hyperlink
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;

  &.#{$link-class-name}--icon .#{$fa-icon-selector},
  & {
    color: $hyperlink-color;
  }

  &:hover,
  &:focus,
  &:focus:hover {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-hover;
    }
  }

  &:active,
  &:focus:active {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-active;
    }
  }

  &:disabled,
  &[disabled='disabled'] {
    opacity: .3;
    pointer-events: none;

    &:hover,
    &:hover:visited,
    &:focus,
    &:focus:visited,
    &:focus:hover:visited,
    &:focus:hover,
    &:visited {
      color: $hyperlink-color;
      box-shadow: none;
    }
  }
}

.#{$link-class-name} {
  font-weight: $font-weight-bold;
  @include transition-with-curve (smooth-fast, 'default', box-shadow);

  &:hover:visited {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-hover;
    }
  }

  &:focus {
    box-shadow: $button-shadow;
  }

  &:focus:visited,
  &:focus:hover:visited {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-hover;
    }
  }

  &:active:visited,
  &:focus:active:visited {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-active;
    }
  }

  &:visited {
    &.#{$link-class-name}--icon .#{$fa-icon-selector},
    & {
      color: $hyperlink-color-visited;
    }
  }

  &.#{$link-class-name}--white {
    &,
    &:focus,
    &:focus:active,
    &:focus:hover,
    &:hover,
    &:active {
      color: $color-white;
    }

    &:focus {
      box-shadow: $icon-btn-dark-box-shadow;
    }
  }
}
