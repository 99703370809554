// Lists
.#{$list-class-name},
.#{$list-class-name}--unordered {
  margin: $list-margin;
  padding-left: $list-padding-left;
  color: $list-color;

  li {
    position: relative;
    padding-left: $list-item-padding-left;
    list-style: none;

    &:before {
      position: absolute;
      margin-left: -$unrdlist-bullet-margin-left;
      content: '\2022';
    }
  }

  ol,
  ul {
    padding-left: $list-sub-padding-left / 2;

    li {
      &:before {
        position: absolute;
        margin-left: -$unrdlist-bullet-margin-left;
        content: '-';
      }
    }
  }
}

// Ordered lists
.#{$list-class-name}--ordered {
  > li {
    list-style-type: $ordrdlist-style-type;

    &:before {
      display: none;
    }
  }
}

// Inline lists
.#{$list-class-name}--inline {
  display: flex;
  padding-left: 0;

  li {
    position: relative;
    margin-left: $inlnlist-item-margin-left;
    padding-left: 0;

    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }

    &:before {
      display: none;
    }

    &:not(:last-child):after {
      display: block;
      position: absolute;
      top: 0;
      right: -$inlnlist-item-margin-left / 2;
      width: $inlnlist-item-divider-width;
      height: 100%;
      border-right: $inlnlist-item-divider-width solid $inlnlist-item-divider-color ;
      content: '';
    }

    li {
      display: none;
    }
  }
}

// Icon lists
.#{$list-class-name}--icon {
  padding-left: $iconlist-padding-left;

  > li {
    padding-left: 0;

    li {
      padding-left: $list-item-padding-left;
    }

    &::before {
      display: none;
    }

    svg {
      margin-right: $iconlist-icon-margin-right;
    }
  }

  ol,
  ul {
    padding-left: $list-sub-padding-left * 1.25;
  }
}