﻿// Form item
$form-item-width-small: 124px !default;
$form-item-width-medium: 186px !default;
$form-item-width-large: 272px !default;
$form-item-margin: 0 24px 16px 0 !default;
$form-item-checkbox-margin-right: 32px !default;
$form-item-checkbox-min-width: 90px;

$form-icon-wrapper-size: 36px !default;

// Input
$input-class-prefix: #{$prefix}input !default;
$input-height: 36px !default;
$input-padding: px-to-rem(7px) px-to-rem($spacing-8) !default;
$input-wrapper-bottom-margin: $spacing-20 !default;
$input-font-size: px-to-rem($font-size-html) !default;
$input-color: $color-black !default;
$input-line-height: 20px !default;
$input-background-color: $color-white !default;
$input-placeholder-color: $color-grey-110 !default;
$input-disabled-opacity: .3 !default;

$input-active-color: $color-blue !default;
$input-active-border-width: 2px !default;

$input-padding-bottom: px-to-rem(1px) !default;
$input-focus-outerglow-color: $color-blue !default;
$input-focus-outerglow-distance: 3px !default;

$input-transition-time: .26s !default;
$input-border-scale-on-focus: 1.6 !default;

// Input readonly
$input-readonly-label-color: $color-grey-110 !default;
$input-readonly-border-color: $color-grey-18 !default;
$input-readonly-background-color: $color-grey-8 !default;
$input-readonly-color: $color-grey-120 !default;

// Input border
$input-border: 1px solid $color-grey-65 !default;
$input-border-radius: 2px !default;
$input-border-color: $color-grey-110 !default;
$input-border-color-hover: $color-blue !default;

// Label
$label-class-name: #{$prefix}label !default;
$label-color: $color-grey-120 !default;
$label-hover: $color-blue !default;
$label-focus-color: $color-blue !default;
$label-size: px-to-rem(12px) !default;
$label-line-height: get-line-height(12px, 20px) !default; // 29
$label-margin-bottom: 4px !default;
$label-inline-margin-right: 8px !default;
$label-inline-line-height: get-line-height(12px, 16px) !default;

// Input description
$input-description-font: 12px !default;
$input-description-color: $color-grey-110 !default;
$input-description-margin-top: 4px !default;
$input-description-line-height: get-line-height($input-description-font, 20px) !default;

// Input description validation
$input-desc-validation-padding-left: px-to-rem(18px) !default;
$input-desc-validation-icon-top: -1px !default;
$input-desc-validation-icon-size: px-to-rem(12.5px) !default;

// Input required
$input-required-asterix-margin-left: 2px !default;

// Icons
$input-validation-icon-size: 20px !default;
$input-validation-icon-position: right 1px !default;
$input-validation-padding-right: px-to-rem(40px) !default;

$input-validation-error-icon: fa-content($fa-var-exclamation-circle) !default;
$input-validation-warning-icon: fa-content($fa-var-exclamation-triangle) !default;
$input-validation-success-icon: fa-content($fa-var-check-circle) !default;
$input-validation-info-icon: fa-content($fa-var-info-circle) !default;

// Default input types icons
@mixin input-icon($type, $color) {
  @if $type == 'password' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="21" height="32" viewBox="0 0 21 32" fill="' + $color + '"><path d="M5.714 13.714h9.143v-3.429q0-1.893-1.339-3.232t-3.232-1.339-3.232 1.339-1.339 3.232v3.429zm14.857 1.715v10.286q0 .714-.5 1.214t-1.214.5H1.714q-.714 0-1.214-.5T0 25.715V15.429q0-.714.5-1.214t1.214-.5h.571v-3.429q0-3.286 2.357-5.643t5.643-2.357 5.643 2.357 2.357 5.643v3.429h.571q.714 0 1.214.5t.5 1.214z"/></svg>');
  }

  @if $type == 'email' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="' + $color + '"><path d="M32 12.679v14.179q0 1.179-.839 2.018t-2.018.839H2.857q-1.179 0-2.018-.839T0 26.858V12.679q.786.875 1.804 1.554 6.464 4.393 8.875 6.161 1.018.75 1.652 1.17t1.688.857 1.964.438h.036q.911 0 1.964-.438t1.688-.857 1.652-1.17q3.036-2.196 8.893-6.161 1.018-.696 1.786-1.554zm0-5.25q0 1.411-.875 2.696t-2.179 2.196q-6.714 4.661-8.357 5.804-.179.125-.759.545t-.964.679-.929.58-1.027.482-.893.161h-.036q-.411 0-.893-.161t-1.027-.482-.929-.58-.964-.679-.759-.545q-1.625-1.143-4.679-3.259t-3.661-2.545q-1.107-.75-2.089-2.063T-.002 7.82q0-1.393.741-2.321t2.116-.929h26.286q1.161 0 2.009.839t.848 2.018z"/></svg>');
  }

  @if $type == 'tel' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="25" height="32" viewBox="0 0 25 32" fill="' + $color + '"><path d="M25.143 22.143q0 .482-.179 1.259t-.375 1.223q-.375.893-2.179 1.893-1.679.911-3.321.911-.482 0-.938-.063t-1.027-.223-.848-.259-.991-.366-.875-.321q-1.75-.625-3.125-1.482-2.286-1.411-4.723-3.848t-3.848-4.723q-.857-1.375-1.482-3.125-.054-.161-.321-.875t-.366-.991-.259-.848-.223-1.027T0 8.34q0-1.643.911-3.321 1-1.804 1.893-2.179.446-.196 1.223-.375t1.259-.179q.25 0 .375.054.321.107.946 1.357.196.339.536.964t.625 1.134.554.955q.054.071.313.446t.384.634.125.509q0 .357-.509.893t-1.107.982-1.107.946-.509.821q0 .161.089.402t.152.366.25.429.205.339q1.357 2.446 3.107 4.196t4.196 3.107q.036.018.339.205t.429.25.366.152.402.089q.321 0 .821-.509t.946-1.107.982-1.107.893-.509q.25 0 .509.125t.634.384.446.313q.446.268.955.554t1.134.625.964.536q1.25.625 1.357.946.054.125.054.375z"/></svg>');
  }

  @if $type == 'url' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="' + $color + '"><path d="M26 21.714q0-.714-.5-1.214l-3.714-3.714q-.5-.5-1.214-.5-.75 0-1.286.571.054.054.339.33t.384.384.268.339.232.455.063.491q0 .714-.5 1.214t-1.214.5q-.268 0-.491-.063t-.455-.232-.339-.268-.384-.384-.33-.339q-.589.554-.589 1.304 0 .714.5 1.214l3.679 3.696q.482.482 1.214.482.714 0 1.214-.464l2.625-2.607q.5-.5.5-1.196zM13.446 9.125q0-.714-.5-1.214L9.267 4.215q-.5-.5-1.214-.5-.696 0-1.214.482L4.214 6.804q-.5.5-.5 1.196 0 .714.5 1.214l3.714 3.714q.482.482 1.214.482.75 0 1.286-.554-.054-.054-.339-.33t-.384-.384-.268-.339-.232-.455-.063-.491q0-.714.5-1.214t1.214-.5q.268 0 .491.063t.455.232.339.268.384.384.33.339q.589-.554.589-1.304zm15.983 12.589q0 2.143-1.518 3.625l-2.625 2.607q-1.482 1.482-3.625 1.482-2.161 0-3.643-1.518l-3.679-3.696q-1.482-1.482-1.482-3.625 0-2.196 1.571-3.732l-1.571-1.571q-1.536 1.571-3.714 1.571-2.143 0-3.643-1.5l-3.714-3.714Q.286 10.143.286 8t1.518-3.625l2.625-2.607Q5.911.286 8.054.286q2.161 0 3.643 1.518L15.376 5.5q1.482 1.482 1.482 3.625 0 2.196-1.571 3.732l1.571 1.571q1.536-1.571 3.714-1.571 2.143 0 3.643 1.5l3.714 3.714q1.5 1.5 1.5 3.643z"/></svg>');
  }

  @if $type == 'search' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="' + $color + '"><path d="M20.571 14.857q0-3.304-2.348-5.652t-5.652-2.348-5.652 2.348-2.348 5.652 2.348 5.652 5.652 2.348 5.652-2.348 2.348-5.652zm9.143 14.857q0 .929-.679 1.607T27.428 32q-.964 0-1.607-.679l-6.125-6.107q-3.196 2.214-7.125 2.214-2.554 0-4.884-.991t-4.018-2.679T.99 19.74t-.991-4.884.991-4.884 2.679-4.018 4.018-2.679 4.884-.991 4.884.991 4.018 2.679 2.679 4.018.991 4.884q0 3.929-2.214 7.125l6.125 6.125q.661.661.661 1.607z"/></svg>');
  }

  @if $type == 'date' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="' + $color + '"><path d="M2.286 29.714h5.143v-5.143H2.286v5.143zm6.285 0h5.714v-5.143H8.571v5.143zm-6.285-6.285h5.143v-5.714H2.286v5.714zm6.285 0h5.714v-5.714H8.571v5.714zm-6.285-6.858h5.143v-5.143H2.286v5.143zm13.143 13.143h5.714v-5.143h-5.714v5.143zM8.571 16.571h5.714v-5.143H8.571v5.143zm13.715 13.143h5.143v-5.143h-5.143v5.143zm-6.857-6.285h5.714v-5.714h-5.714v5.714zM9.143 8V2.857q0-.232-.17-.402t-.402-.17H7.428q-.232 0-.402.17t-.17.402V8q0 .232.17.402t.402.17h1.143q.232 0 .402-.17T9.143 8zm13.143 15.429h5.143v-5.714h-5.143v5.714zm-6.857-6.858h5.714v-5.143h-5.714v5.143zm6.857 0h5.143v-5.143h-5.143v5.143zM22.857 8V2.857q0-.232-.17-.402t-.402-.17h-1.143q-.232 0-.402.17t-.17.402V8q0 .232.17.402t.402.17h1.143q.232 0 .402-.17t.17-.402zm6.857-1.143v22.857q0 .929-.679 1.607T27.428 32H2.285q-.929 0-1.607-.679t-.679-1.607V6.857q0-.929.679-1.607t1.607-.679h2.286V2.857q0-1.179.839-2.018T7.428 0h1.143q1.179 0 2.018.839t.839 2.018v1.714h6.857V2.857q0-1.179.839-2.018T21.142 0h1.143q1.179 0 2.018.839t.839 2.018v1.714h2.286q.929 0 1.607.679t.679 1.607z"/></svg>');
  }

  @if $type == 'time' {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="' + $color + '"><path d="M16 9.714v8q0 .25-.161.411t-.411.161H9.714q-.25 0-.411-.161t-.161-.411v-1.143q0-.25.161-.411t.411-.161h4V9.713q0-.25.161-.411t.411-.161h1.143q.25 0 .411.161t.161.411zM23.429 16q0-2.643-1.304-4.875t-3.536-3.536-4.875-1.304-4.875 1.304-3.536 3.536T3.999 16t1.304 4.875 3.536 3.536 4.875 1.304 4.875-1.304 3.536-3.536T23.429 16zm4 0q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991T.001 16 1.84 9.116t4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991T27.429 16z"/></svg>');
  }
}

$input-validation-icons: (
  error: $input-validation-error-icon,
  warning: $input-validation-warning-icon,
  success: $input-validation-success-icon,
  info: $input-validation-info-icon
);

// Input validation colors
$input-validation-colors: (
  error: $color-alert,
  warning: $color-warning,
  warning-text: $color-warning-text,
  success: $color-success,
  info: $color-info
);

// Input types with icon
$input-icon-padding-left: px-to-rem(36px) !default;
$input-readonly-icon-padding-left: px-to-rem(28px) !default;
$input-icon-background-position: left 8px center !default;
$input-icon-background-size: 16px 16px !default;
$input-icon-color: $color-grey-65 !default;
$input-icon-color-focus: $color-grey-120 !default;
$input-icon-size: 1rem;

// Input group
$input-group-text-padding: px-to-rem(8px) !default;
$input-group-text-color: $color-grey-110 !default;
$input-group-text-line-height: get-line-height($font-size-body, 18px) !default;
$input-group-icon-padding: px-to-rem(5px) px-to-rem(8px) !default;
$input-group-icon-line-height: get-line-height($font-size-body, 10px) !default;
$input-group-icon-width: 40px;

// Select
$input-select-color: $input-color !default;
$input-select-class-name: #{$input-class-prefix}--select !default;
$input-select-min-width: 183px !default; // 183
$input-select-padding-top: px-to-rem(6px) !default;
$input-select-padding-bottom: px-to-rem(3px) !default;
$input-select-padding-right: px-to-rem(32px) !default;
$input-select-border-color: $input-border-color !default;
$input-select-background-color: $input-background-color !default;
$input-select-arrow-icon-size: 8px !default;
$input-select-arrow-icon-position: right 12px center !default;
$input-select-arrow-icon-color: $color-grey-120;
$input-multiple-height: 65px !default;

@mixin input-select-arrow-icon($color) {
  background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="32" viewBox="0 0 18 32" fill="' + $color + '"><path d="M18.286 12.571q0 .464-.339.804l-8 8q-.339.339-.804.339t-.804-.339l-8-8Q0 13.036 0 12.571t.339-.804.804-.339h16q.464 0 .804.339t.339.804z"/></svg>');
};

// Radio
$input-radio-class-name: #{$input-class-prefix}--radio !default;
$input-radio-click-area-size: 24px !default;
$input-radio-background: $color-white !default;
$input-radio-size: 24px !default;
$input-radio-text-line-height: 2.25; // 36
$input-radio-text-top-padding: px-to-rem(3px) !default;
$input-radio-text-padding: px-to-rem(6px) !default;
$input-radio-text-left-padding: px-to-rem(40px) !default;
$input-radio-margin-right: $spacing-16 !default;
$input-radio-border-size: 1px !default;
$input-radio-border-style: solid !default;
$input-radio-border-color: $color-grey-65 !default;
$input-radio-hover-color: $color-btn-happy-hover !default;
$input-radio-hover-background-color: $color-paleblue;
$input-radio-focus-color: $color-blue !default;
$input-radio-active-color: $color-blue !default;
$input-radio-active-circle-size: 8px !default;
$input-radio-active-circle-color: $color-white !default;
$input-radio-checked-background-color: $color-blue !default;
$input-radio-transition-curve: smooth-out !default;
$input-radio-transition-duration: .35s !default;

// Checkbox
$input-checkbox-class-name: #{$input-class-prefix}--checkbox !default;
$input-checkbox-click-area-size: 24px !default;
$input-checkbox-size: 24px !default;
$input-check-text-line-height: 2 !default; // 34
$input-checkbox-text-top-padding: px-to-rem(3px) !default;

$input-checkbox-text-right-padding: px-to-rem(6px) !default;
$input-checkbox-text-left-padding: px-to-rem(40px) !default;
$input-checkbox-margin-right: $spacing-16 !default;
$input-checkbox-background: $color-white !default;
$input-checkbox-border-size: 1px !default;
$input-checkbox-border-style: solid !default;
$input-checkbox-border-color: $color-grey-65 !default;
$input-checkbox-hover-color: mix($color-blue, $color-black, 80%) !default;
$input-checkbox-focus-color: mix($color-blue, $color-black, 80%) !default;
$input-checkbox-hover-background: $color-paleblue;
$input-checkbox-active-color: $color-blue !default;
$input-checkbox-checked-background-color: $color-blue !default;

$input-checkbox-tick-width: 19px !default;
$input-checkbox-tick-height: 9px !default;
$input-checkbox-tick-top: 14px !default;
$input-checkbox-tick-left: 4px !default;
$input-checkbox-small-tick-width: 14px !default;
$input-checkbox-small-tick-height: 7px !default;
$input-checkbox-small-tick-top: 9px !default;
$input-checkbox-small-tick-left: 2px !default;

$checkbox-tick-width: (
  default: $input-checkbox-tick-width,
  small: $input-checkbox-small-tick-width
);

$checkbox-tick-height: (
  default: $input-checkbox-tick-height,
  small: $input-checkbox-small-tick-height
);

// Input small (checkbox and radio modifier)
$input-small-size: 16px;
$input-small-padding-left: px-to-rem(24px);
$input-small-margin-bottom: 8px;

// Input file
$input-file-class: #{$input-class-prefix}--file !default;
$input-file-label-class: #{$input-file-class}__label !default;
$input-file-name-class: #{$input-file-class}__name !default;
$input-file-name-padding: px-to-rem(8px) !default;

// Text area
$input-textarea-class: #{$input-class-prefix}--textarea !default;
$textarea-min-height: 75px !default;
$text-counter-color: $color-grey-110 !default;
$text-counter-size: 14px;

$group-margin-bottom: $spacing-20 !default;
