.#{$header-class-name} {
  $self: &;
  display: flex;
  flex-direction: column;
  padding: px-to-rem($header-spacing) 0 px-to-rem($header-bottom-spacing);
  border-bottom: $header-bottom-border;
  background-color: $header-background-color;

  @include media-breakpoint-down(sm) {
    padding: px-to-rem($header-spacing) 0;
  }

  &#{ $self }--condensed {
    padding-top: px-to-rem($header-condensed-spacing);
    padding-bottom: px-to-rem($header-condensed-spacing);

    .#{$tabs-class-name} {
      margin-bottom: - $header-condensed-spacing;

      @include media-breakpoint-down(sm) {
        margin-bottom: - $header-spacing;
      }
    }
  }

  &#{ $self }--dashboard {
    border: 0;
    background: transparent;
  }

  .#{$breadcrumb-class-name} {
    margin: 0 0 $header-breadcrumb-margin-bottom;
  }

  .#{$tabs-class-name} {
    margin-top: $header-tabs-margin-top;
    margin-bottom: -$header-bottom-spacing;
    border-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin: $header-tabs-margin-top (-$header-spacing) (-$header-spacing);
    }
  }

  &__title {
    display: flex;
    align-items: start;
    margin-top: $header-title-margin-top;
    font-size: px-to-rem($h1-font-size);
    font-weight: $font-weight-normal;
    line-height: $h1-line-height;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      font-size: px-to-rem($h2-font-size);
      line-height: $h2-line-height;
    }

    #{ $self }--condensed & {
      margin-top: 0;
      font-size: px-to-rem($h2-font-size);
      line-height: $h2-line-height;

      @include media-breakpoint-down(sm) {
        font-size: px-to-rem($h4-font-size);
        line-height: $h4-line-height;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    min-height: $header-action-min-height;
    margin-left: auto;

    #{ $self }--condensed & {
      min-height: $header-action-min-height - 4;
    }
  }

  &__subtitle {
    margin: $header-subtitle-margin;
    color: $header-subtitle-color;
  }

  &__record {
    margin-top: $header-spacing;
    line-height: 1;

    &:last-child {
      @include media-breakpoint-up(md) {
        margin-bottom: -$header-spacing / 2;
      }
    }

    #{ $self }--condensed & {
      margin-bottom: 0;
    }
  }
}