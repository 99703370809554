﻿// Icon container
.#{$icon-class-name}-container {
  display: inline-block;
  padding: .5rem;
  background-color: $color-grey-120;
}

// Multiple icon sizes are available. Default, xxsmall, xsmall, small, large, xlarge and xxlarge
// 48
.#{$fa-icon-selector}.#{$icon-class-name} {

  // Text fill
  &--text {
    color: $icon-default-color !important;
  }

  // Color fills
  &--white {
    color: $color-white !important;
  }

  // Notifications
  &--error {
    color: $icon-alert-color !important;
  }

  &--warning {
    color: $icon-warning-color !important;
  }

  // Input group button icon blue
  &--blue path {
    &:not(:disabled) {
      &,
      &:hover,
      &:focus,
      &:focus:hover,
      &:focus:active {
        fill: $color-blue !important;
      }
    }
  }

  // Size and spacing depending on text style
  &--headline,
  &--title,
  &--subheading-2,
  &--subheading-1,
  &--body-2 {
    width: map-get($icon-size-values, l) !important;
    height: map-get($icon-size-values, l) !important;
    margin-right: 1rem;
  }

  &--subheading-2,
  &--subheading-1,
  &--body-2 {
    vertical-align: bottom;
  }

  &--body-1,
  &--caption {
    margin-right: .5rem;
    vertical-align: sub;
  }
}

// Font awesome styles
.#{$fa-icon-selector} {
  width: $icon-base-size;
  height: $icon-base-size;
  color: $icon-default-color;
}

// xs
.#{$fa-css-prefix}-xs { font-size: .75rem; }

.#{$fa-icon-selector}.#{$fa-css-prefix}-xs {
  width: map-get($icon-size-values, xs) !important;
  height: map-get($icon-size-values, xs) !important;
}

// sm
.#{$fa-css-prefix}-sm { font-size: .875rem; }

.#{$fa-icon-selector}.#{$fa-css-prefix}-sm {
  width: map-get($icon-size-values, s) !important;
  height: map-get($icon-size-values, s) !important;
}

// lg
.#{$fa-css-prefix}-lg { font-size: px-to-rem(24px); }

.#{$fa-icon-selector}.#{$fa-css-prefix}-lg {
  width: map-get($icon-size-values, l) !important;
  height: map-get($icon-size-values, l) !important;
}

@for $i from 1 through 10 {
  #{$fa-css-prefix}-#{$i}x {
    font-size: $i * 1rem;
  }

  .#{$fa-icon-selector}.#{$fa-css-prefix}-#{$i}x {
    width: $i * $icon-base-size !important;
    height: $i * $icon-base-size !important;
  }
}
