// Margin. Set margin on an element by using this function
@function margin($top, $right: $top, $bottom: $top, $left: $right) {
  @if $top == $right and $top == $bottom and $top == $left {
    @return $top;
  } @else if $top == $bottom and $right == $left {
    @return $top $right;
  } @else {
    @return $top $right $bottom $left;
  }
}

// Padding. Set padding on an element by using this function
@function padding($top, $right: $top, $bottom: $top, $left: $right) {
  @if $top == $right and $top == $bottom and $top == $left {
    @return $top;
  } @else if $top == $bottom and $right == $left {
    @return $top $right;
  } @else {
    @return $top $right $bottom $left;
  }
}

// Border-radius easily set with the use of this mixin
@mixin border-radius($top-left, $top-right: $top-left, $bottom-right: $top-left, $bottom-left: $top-right) {
  @if $top-left == $top-right and $top-left == $bottom-right and $top-left == $bottom-left {
    border-radius: $top-left;
  } @else if $top-left == $bottom-right and $top-right == $bottom-left {
    border-radius: $top-left $top-right;
  } @else {
    border-radius: $top-left $top-right $bottom-right $bottom-left;
  }
}

// The border shorthand property sets all the border properties in one declaration.
@mixin border($style: solid, $color: $color-black, $top: 1px, $right: $top, $bottom: $top, $left: $right) {
  @if $top == $right and $top == $bottom and $top == $left {
    border-width: $top;
  } @else if $top == $bottom and $right == $left {
    border-width: $top $right;
  } @else {
    border-width: $top $right $bottom $left;
  }

  border-style: $style;
  border-color: $color;
}

// The CSS transform property lets you modify the coordinate space of the CSS visual formatting model.
@mixin transform($transforms) {
  transform: $transforms;
}

// The rotate() CSS function defines a transformation that moves the element around a fixed point (as specified by the transform-origin property) without deforming it.
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// The scale() CSS function modifies the size of the element.
@mixin scale($scale) {
  @include transform(scale($scale));
}

// The translate() CSS function moves the position of the element on the plane.
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// The skew() CSS function is a shear mapping, or transvection, distorting each point of an element by a certain angle in each direction.
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// The transform-origin property lets you modify the origin for transformations of an element.
@mixin transform-origin ($origin) {
  transform-origin: $origin;
}

// Controls the actual selection operation. This doesn't have any effect on content loaded as chrome, except in textboxes.
@mixin user-select($select) {
  user-select: $select;
}

// Get breakpoint for screen size
@function screen-size-breakpoint($screen-size, $breakpoint-limit: min) {
  @return map-get(map-get($screen-sizes-breakpoints, $screen-size), $breakpoint-limit);
}

// Media query for screen size breakpoint
@mixin only-for-screen-type ($screen-size) {
  $min: 'and (min-width: #{screen-size-breakpoint($screen-size, min)})';
  $max: 'and (max-width: #{screen-size-breakpoint($screen-size, max)})';

  @if ($screen-size == 'tiny') {
    $min: '';
  }

  @if ($screen-size == 'xxlarge') {
    $max: '';
  }

  $media-content: 'screen #{$min} #{$max}';

  @media #{$media-content} {
    @content;
  }
}

// Styles for Body2 font style
@mixin body2-font-style {
  font-size: px-to-rem($font-size-body);
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
}
