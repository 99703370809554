.#{$prefix}icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $icon-btn-size;
  height: $icon-btn-size;
  border: 0;
  border-radius: $icon-btn-border-radius;
  background-color: transparent;
  cursor: pointer;
  box-shadow: none;
  @include transition-with-curve (smooth-fast, 'default', background-color, box-shadow);

  .#{$fa-icon-selector} {
    color: $icon-btn-color;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: $icon-btn-shadow;
  }

  &:hover,
  &:focus {
    .#{$fa-icon-selector} {
      color: $icon-btn-color-hover;
    }
  }

  &:active {
    .#{$fa-icon-selector} {
      color: $icon-btn-color-active;
    }
  }

  // Disabled
  &[disabled] {
    cursor: not-allowed;
    opacity: .3;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;

      .#{$fa-icon-selector} {
        color: $icon-btn-color;
      }
    }
  }

  &--small {
    width: $icon-btn-small-size;
    height: $icon-btn-small-size;
  }

  // With border and background
  &--border {
    border: $icon-btn-border;
    border-radius: $icon-btn-border-border-radius;
    background-color: $icon-btn-border-background-color;

    &:hover,
    &:focus {
      border-color: $icon-btn-border-hover;
      background-color: $icon-btn-border-background-color-hover;
    }

    &:active {
      background-color: $icon-btn-border-background-color-active;
    }

    &[disabled] {
      border: $icon-btn-border;
      background-color: $icon-btn-border-background-color;
    }
  }

  // Dark
  &--dark {
    .#{$fa-icon-selector} {
      color: $icon-btn-dark-color;
    }

    &:hover,
    &:focus,
    &:active {
      .#{$fa-icon-selector} {
        color: $icon-btn-dark-color-hover;
      }
    }

    &:focus,
    &:active {
      box-shadow: $icon-btn-dark-box-shadow;
    }

    &[disabled] {
      &:hover,
      &:focus,
      &:active {
        .#{$fa-icon-selector} {
          color: $icon-btn-dark-color;
        }
      }
    }
  }
}