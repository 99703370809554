.#{$inline-message-class-name} {
  color: $inline-message-color;
  font-size: $inline-message-font-size;

  &__text {
    &:before {
      display: none;
      @include font-awesome;
    }
  }

  .#{$fa-icon-selector} {
    width: $inline-message-icon-size;
    height: $inline-message-icon-size;
    margin-right: $inline-message-icon-margin-right;
  }

  &--medium {
    font-size: $inline-message-medium-font-size;

    .#{$fa-icon-selector} {
      width: $inline-message-medium-icon-size;
      height: $inline-message-medium-icon-size;
      margin-right: $inline-message-medium-icon-margin-right;
    }
  }

  @each $validation-state in error, warning, success {
    &--#{$validation-state} {
      color: map-get($input-validation-colors, $validation-state);

      .#{$inline-message-class-name}__text {
        &::before {
          content: map-get($input-validation-icons, $validation-state);
        }

        .#{$fa-icon-selector} {
          color: map-get($input-validation-colors, $validation-state);
        }
      }
    }
  }
}