html {
  min-width: 250px;
  height: 100%; }
  html * {
    -webkit-overflow-scrolling: touch; }

.flex-panel-content--row > .flex-container {
  padding: 8px; }
  @media screen and (max-width: 570px) {
    .flex-panel-content--row > .flex-container {
      padding: 0; } }

.flex-panel-content--col > .flex-container + .flex-container {
  padding-top: 16px; }

.flex-container {
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  flex-flow: row;
  flex-wrap: wrap; }
  .flex-container.is-dark {
    background-color: #4a5c69; }
  .flex-container > .panel,
  .flex-container > .flex-container {
    flex: auto;
    margin: 8px; }
    @media (max-width: 600px) {
      .flex-container > .panel,
      .flex-container > .flex-container {
        margin: 4px; } }
  .flex-container > .flex-container {
    margin: 0; }
  .flex-container.flex-container--row {
    flex-direction: row; }
    .flex-container.flex-container--row.reverse {
      flex-direction: row-reverse; }
  .flex-container.flex-container--col {
    flex-direction: column; }
    .flex-container.flex-container--col.reverse {
      flex-direction: column-reverse; }
  .flex-container.justify-content-flex-start {
    justify-content: flex-start; }
  .flex-container.justify-content-flex-end {
    justify-content: flex-end; }
  .flex-container.justify-content-center {
    justify-content: center; }
  .flex-container.justify-content-space-between {
    justify-content: space-between; }
  .flex-container.justify-content-space-around {
    justify-content: space-around; }
  .flex-container.justify-content-space-evenly {
    justify-content: space-evenly; }
  .flex-container.align-self-auto {
    align-self: auto; }
  .flex-container.align-self-flex-start {
    align-self: flex-start; }
  .flex-container.align-self-flex-end {
    align-self: flex-end; }
  .flex-container.align-self-center {
    align-self: center; }
  .flex-container.align-self-baseline {
    align-self: baseline; }
  .flex-container.align-self-stretch {
    align-self: stretch; }
  .flex-container.align-items-flex-start {
    align-items: flex-start; }
  .flex-container.align-items-flex-end {
    align-items: flex-end; }
  .flex-container.align-items-center {
    align-items: center; }
  .flex-container.align-items-baseline {
    align-items: baseline; }
  .flex-container.align-items-stretch {
    align-items: stretch; }
  .flex-container .align-content-flex-start {
    align-content: flex-start; }
  .flex-container .align-content-flex-end {
    align-content: flex-end; }
  .flex-container .align-content-center {
    align-content: center; }
  .flex-container .align-content-space-between {
    align-content: space-between; }
  .flex-container .align-content-space-around {
    align-content: space-around; }
  .flex-container .align-content-stretch {
    align-items: stretch; }
