.#{$breadcrumb-class-name} {
  margin: $breadcrumb-horizontal-margin 0;
  padding: 0;

  &__item {
    display: inline-block;
    position: relative;
    margin-right: $breadcrumb-margin-right;
    color: $color-grey-120;
    font-weight: $breadcrumb-font-weight;

    &:not(:last-child):after {
      display: none;
      content: fa-content($fa-var-angle-right);
      @include font-awesome;
    }

    svg.svg-inline--fa {
      position: absolute;
      top: $breadcrumb-icon-top;
      right: $breadcrumb-icon-right;
      width: $breadcrumb-icon-width;
      height: $breadcrumb-icon-height;
      color: $color-grey;
    }
  }

  a {
    @include transition-with-curve (smooth-fast, 'default', box-shadow);

    &:focus {
      box-shadow: $button-shadow;
    }
  }
}
