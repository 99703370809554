// Input styles
.#{$input-class-prefix} {
  position: relative;
  order: 2;
  min-width: $form-item-width-small;
  max-width: 100%;
  padding: $input-padding;
  border: $input-border;
  border-radius: $input-border-radius;
  background-color: $input-background-color;
  background-repeat: no-repeat;
  color: $input-color;
  line-height: $input-line-height;
  @include transition-with-curve (smooth-fast, 'default', box-shadow);

  .form__item--inline & {
    flex: 1;
  }

  &:hover {
    border-color: $input-border-color-hover;

    + label {
      color: $label-hover;
    }
  }

  &:focus {
    border-color: $input-border-color-hover;
    outline: none;
    box-shadow: $button-shadow;

    + label {
      color: $label-hover;
    }

    ~ .form__icon svg {
      color: $input-icon-color-focus;
    }
  }

  ~ .form__icon svg {
    color: $input-icon-color;
  }

  &__wrap--inline & {
    width: auto;
  }

  // Description and counter
  &__description {
    order: 3;

    &--validation {
      display: none;

      &::before {
        display: none;
        @include font-awesome;
      }

      svg.svg-inline--fa {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }

  &__description,
  &__counter {
    color: $input-description-color;
    font-size: px-to-rem($input-description-font);
    line-height: $input-description-line-height;
  }

  &-counter__wrapper {
    display: flex;
    justify-content: space-between;
    order: 3;
  }

  &__counter {
    order: 4;
  }

  // Validation
  &.is-error,
  &.is-warning,
  &.is-success,
  &.is-info {
    &:hover,
    &:focus {
      border-color: $input-border-color-hover;

      + label {
        color: $label-hover;
      }
    }

    ~ .#{$input-class-prefix}__description--validation {
      display: block;
    }
  }

  @each $validation-state in error, warning, success, info {
    &.is-#{$validation-state} {
      border-color: map-get($input-validation-colors, $validation-state);

      @if $validation-state == 'warning' {
        ~ * {
          color: map-get($input-validation-colors, warning-text);
        }

        ~ * > * {
          color: map-get($input-validation-colors, $validation-state);
        }
      } @else {
        ~ *,
        ~ * > * {
          color: map-get($input-validation-colors, $validation-state);
        }
      }

      ~ .#{$input-class-prefix}__description::before {
        content: map-get($input-validation-icons, $validation-state);
      }
    }
  }

  // Types
  &[type='password'],
  &[type='email'],
  &[type='tel'],
  &[type='url'],
  &[type='search'],
  &[type='date'],
  &[type='time'] {
    padding-left: $input-icon-padding-left;
    background-position: $input-icon-background-position;
    background-size: $input-icon-background-size;
  }

  // Remove default webkit date and time controllers
  &[type='date'],
  &[type='time'] {
    // sass-lint:disable-block no-vendor-prefixes
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  @each $input-type in password, email, tel, url, search, date, time {
    &[type='#{$input-type}']:not([readonly]) {
      @include input-icon($input-type, $input-icon-color);

      &:focus {
        @include input-icon($input-type, $input-icon-color-focus);
      }
    }

    &[type='#{$input-type}'][readonly] {
      @include input-icon($input-type, $input-icon-color-focus);
    }
  }

  // Required
  &[required] {
    + label:after {
      margin-left: $input-required-asterix-margin-left;
      color: $color-alert;
      content: '*';
    }
  }

  // Disabled
  &[disabled] {
    &,
    ~ * {
      opacity: $input-disabled-opacity;
    }

    &:hover {
      border: $input-border;

      + label {
        color: $label-color;
      }
    }
  }

  // Read only
  &[readonly] {
    border-color: $input-readonly-border-color;
    background-color: $input-readonly-background-color;
    color: $input-readonly-color;

    + label {
      color: $input-readonly-label-color;
    }

    &:focus {
      border-color: $input-border-color-hover;
    }

    &:hover,
    &:focus {
      + label {
        color: $input-readonly-label-color;
      }
    }
  }
}

// Placeholders. Can't be merged in one rule.
::placeholder { // Firefox 18-
  color: $input-placeholder-color;
}

input:not(.#{$table-class-name}--input)::placeholder {
  color: $input-placeholder-color;
}

input.#{$table-class-name}--input::placeholder {
  color: $input-placeholder-color;
}

[contenteditable='true'] {
  &:focus {
    position: absolute;
    top: 1px;
    right: 0;
    left: 0;
    height: $table-cell-editable-height;
    padding: $table-cell-editable-padding;
    border: $table-cell-editable-border;
    border-radius: $table-cell-editable-border-radius;
    outline: none;
    background-color: $table-cell-editable-background-color;
    box-shadow: $table-cell-editable-box-shadow;
  }
}
