// Button group
.#{$prefix}btn-group {
  display: inline-flex;
  position: relative;

  .#{$prefix}btn {
    position: relative;
    margin: 0;
    z-index: 0;

    &:focus {
      z-index: 1;
    }
  }

  > .#{$prefix}btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &--positive,
    &--negative,
    &--happy {
      &,
      &:focus:hover,
      &:focus:active {
        border-right: 1px solid $color-white;
      }
    }
  }

  .#{$prefix}menu__wrapper {
    display: inline-block;

    .#{$prefix}btn {
      width: $button-max-height-default;
      min-width: auto;
      padding: 0;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &--small {
        width: $button-max-height-small;
      }

      &--large {
        width: $button-max-height-large;
      }

      .#{$fa-icon-selector} {
        width: $button-icon-size-small;
        height: $button-icon-size-small;
        margin-right: 0;
      }
    }
  }
}