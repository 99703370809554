.#{$message-box-class-name} {
  position: relative;
  border: $message-box-border-width solid;
  border-radius: $message-box-border-radius;

  &:hover,
  &.#{$message-box-class-name}--focus {
    border-width: $message-box-hover-border-width;
  }

  &--warning {
    border-color: $message-box-warning-border-color;
  }

  &--error {
    border-color: $message-box-error-border-color;
  }

  &--bold {
    &.#{$message-box-class-name}--warning {
      background-color: $message-box-bold-warning-background-color;
    }

    &.#{$message-box-class-name}--error {
      background-color: $message-box-bold-error-background-color;
      color: $message-box-bold-error-color;

      &:hover:after,
      &.#{$message-box-class-name}--focus:after {
        display: block;
        position: absolute;
        top: $message-box-bold-error-hover-border-offset;
        right: $message-box-bold-error-hover-border-offset;
        bottom: $message-box-bold-error-hover-border-offset;
        left: $message-box-bold-error-hover-border-offset;
        border: $message-box-hover-border-width solid $message-box-bold-error-hover-border-color;
        content: '';
        z-index: 0;
      }

      &:hover,
      &.#{$message-box-class-name}--focus {
        border-width: $message-box-border-width;
      }

      .#{$link-class-name} {
        @extend .#{$link-class-name}--white;
      }
    }
  }

  &__header {
    display: flex;
    position: relative;
    flex: 1;
    padding: $message-box-padding;
    cursor: pointer;
    z-index: 1;

    &:focus {
      outline: none;
    }

    .#{$message-box-class-name}:hover &,
    .#{$message-box-class-name}--focus & {
      padding: $message-box-hover-padding;
    }

    .#{$message-box-class-name}--error.#{$message-box-class-name}--bold:hover &,
    .#{$message-box-class-name}--error.#{$message-box-class-name}--bold.#{$message-box-class-name}--focus & {
      padding: $message-box-padding;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: $message-box-icon-margin-right;

    .#{$fa-icon-selector} {
      .#{$message-box-class-name}--warning & {
        color: $color-warning;
      }

      .#{$message-box-class-name}--error & {
        color: $color-alert;
      }

      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold & {
        color: $message-box-bold-error-icon-color;
      }
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__expand {
    display: flex;
    align-items: center;
    margin-left: auto;
    transform-origin: center;
    @include transition-with-curve (smooth-fast, 'default', transform);

    .#{$message-box-class-name}--expanded & {
      transform: rotate(180deg);
    }

    .#{$fa-icon-selector} {
      .#{$message-box-class-name}:hover & {
        color: $message-box-expand-icon-hover-color;
      }

      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold &,
      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold:hover &,
      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold.#{$message-box-class-name}--focus & {
        color: $message-box-bold-error-expand-icon-hover-color;
      }
    }
  }

  &__content {
    position: relative;
    height: 0;
    padding: $message-box-content-padding;
    color: $message-box-content-color;
    line-height: $message-box-content-line-height;
    overflow: hidden;
    z-index: 1;
    @include transition-with-curve (smooth-fast, 'default', height);

    .#{$message-box-class-name}:hover &,
    .#{$message-box-class-name}--focus & {
      padding: $message-box-content-hover-padding;
    }

    .#{$message-box-class-name}--error.#{$message-box-class-name}--bold:hover &,
    .#{$message-box-class-name}--error.#{$message-box-class-name}--bold.#{$message-box-class-name}--focus & {
      padding: $message-box-content-padding;
    }

    .#{$message-box-class-name}--error.#{$message-box-class-name}--bold & {
      color: $message-box-bold-error-color;
    }

    &:after {
      display: block;
      height: $message-box-content-margin;
      content: '';

      .#{$message-box-class-name}:hover &,
      .#{$message-box-class-name}--focus & {
        height: $message-box-content-margin - $message-box-border-width;  
      }

      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold:hover &,
      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold.#{$message-box-class-name}--focus & {
        height: $message-box-content-margin;
      }
    }

    &:before {
      .#{$message-box-class-name}:hover &,
      .#{$message-box-class-name}--focus & {
        display: block;
        height: $message-box-border-width;
        content: '';
      }

      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold:hover &,
      .#{$message-box-class-name}--error.#{$message-box-class-name}--bold.#{$message-box-class-name}--focus & {
        height: 0;
      }
    }

    &--is-visible {
      height: auto;
    }
  }
}