.tab-wrapper {
  position: relative;
  padding: 0 !important; }
  .tab-wrapper .ps__rail-y {
    display: none; }
  .tab-wrapper .tabs__tab-active-bar {
    bottom: 0;
    z-index: 0; }
  .tab-wrapper .tabs {
    margin-top: 8px;
    padding: 0 !important;
    max-height: 43px; }
    .tab-wrapper .tabs.tabs--with-icon {
      max-height: 60px; }
      .tab-wrapper .tabs.tabs--with-icon .tabs__tab a > svg {
        display: block;
        width: 24px;
        height: 24px;
        margin: auto; }
    .tab-wrapper .tabs .tabs__tab-active-bar {
      display: none; }
    .tab-wrapper .tabs li:first-of-type .tabs__tab-active-bar {
      display: block; }

.topbar .tabs a[disabled] {
  opacity: 0.3;
  pointer-events: none; }
