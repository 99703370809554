@function px-to-rem($value) {
  $rem: $value / $font-size-html;
  @return #{$rem}rem;
}

@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

$class-name-typography:	#{$prefix}text !default;
$base-unit: 'rem' !default;
$font-family-sans-serif: 'Helvetica Neue', Arial, sans-serif !default;
$font-family-base: 'OpenSans', $font-family-sans-serif;

// Font base sizes
$font-size-html: 16px !default;
$font-size-body: 14px !default;

// Base line height
$line-height-base: get-line-height($font-size-body, 24px) !default;

// Weights
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

// Caption
$caption-font-size: 12px !default;
$caption-line-height: get-line-height($caption-font-size, 20px) !default;

// Headings sizes
$h1-font-size: 25px !default;
$h2-font-size: 20px !default;
$h3-font-size: 16px !default;
$h4-font-size: 16px !default;
$h5-font-size: 14px !default;
$h6-font-size: 14px !default;

// Headings line-height
$h1-line-height: get-line-height($h1-font-size, 36px) !default;
$h2-line-height: get-line-height($h2-font-size, 28px) !default;
$h3-line-height: get-line-height($h3-font-size, 24px) !default;
$h4-line-height: get-line-height($h4-font-size, 24px) !default;
$h5-line-height: get-line-height($h5-font-size, 24px) !default;
$h6-line-height: get-line-height($h6-font-size, 20px) !default;

// Headings margin bottom
$h1-margin-bottom: 16px !default;
$h2-margin-bottom: 16px !default;
$h3-margin-bottom: 16px !default;
$h4-margin-bottom: 8px !default;
$h5-margin-bottom: 8px !default;
$h6-margin-bottom: 8px !default;

// Display sizes
$display3-font-size: 73px !default;
$display2-font-size: 51px !default;
$display1-font-size: 32px !default;

// Display line heights
$display3-line-height: get-line-height($display3-font-size, 92px) !default;
$display2-line-height: get-line-height($display2-font-size, 64px) !default;
$display1-line-height: get-line-height($display1-font-size, 48px) !default;

// Display margin bottom
$display3-margin-bottom: 24px !default;
$display2-margin-bottom: 24px !default;
$display1-margin-bottom: 16px !default;

// Display weights
$display3-weight: $font-weight-light !default;
$display2-weight: $font-weight-light !default;
$display1-weight: $font-weight-normal !default;
