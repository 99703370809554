$message-box-class-name: #{$prefix}message-box !default;
$message-box-padding: px-to-rem(7px) px-to-rem(15px);
$message-box-content-padding: 0 1rem;
$message-box-content-hover-padding: 0 px-to-rem(15px);
$message-box-border-width: 1px;
$message-box-border-radius: 4px;
$message-box-hover-padding: px-to-rem(6px) px-to-rem(14px);
$message-box-hover-border-width: 2px;
$message-box-warning-border-color: $color-warning;
$message-box-error-border-color: $color-alert;
$message-box-bold-warning-background-color: $color-warning-pale;
$message-box-bold-error-background-color: $color-alert;
$message-box-bold-error-color: $color-white;
$message-box-bold-error-hover-border-offset: 2px;
$message-box-bold-error-hover-border-color: $color-white;
$message-box-icon-margin-right: 8px;
$message-box-bold-error-icon-color: $color-white;
$message-box-expand-icon-hover-color: $color-black;
$message-box-bold-error-expand-icon-hover-color: $color-white;
$message-box-content-color: $color-grey-120;
$message-box-content-line-height: get-line-height(14px, 20px);
$message-box-content-margin: 16px;