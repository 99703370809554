.content-slider {
  display: block; }
  .content-slider .content-slider__wrapper {
    position: relative;
    display: unset;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    max-width: unset;
    max-height: unset;
    justify-content: center; }
    .content-slider .content-slider__wrapper .content-slider__content {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - 50px);
      max-width: unset;
      max-height: unset;
      justify-content: center; }
      .content-slider .content-slider__wrapper .content-slider__content .main-image {
        position: absolute;
        right: 0; }
        .content-slider .content-slider__wrapper .content-slider__content .main-image_foward {
          position: absolute;
          transform: translateX(-100%);
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s;
          transition-timing-function: ease-out; }
        .content-slider .content-slider__wrapper .content-slider__content .main-image_backward {
          position: absolute;
          transform: translateX(100%);
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s;
          transition-timing-function: ease-out; }
      .content-slider .content-slider__wrapper .content-slider__content .previous-image {
        position: absolute;
        right: 100%; }
        .content-slider .content-slider__wrapper .content-slider__content .previous-image_foward {
          display: none; }
        .content-slider .content-slider__wrapper .content-slider__content .previous-image_backward {
          position: absolute;
          right: 100%;
          transform: translateX(100%);
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s;
          transition-timing-function: ease-out; }
      .content-slider .content-slider__wrapper .content-slider__content .next-image {
        position: absolute;
        left: 100%; }
        .content-slider .content-slider__wrapper .content-slider__content .next-image_foward {
          position: absolute;
          left: 100%;
          transform: translateX(-100%);
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s;
          transition-timing-function: ease-out; }
        .content-slider .content-slider__wrapper .content-slider__content .next-image_backward {
          display: none; }
  .content-slider--vertical {
    display: flex; }
    .content-slider--vertical .content-slider__wrapper {
      width: 100%;
      margin-right: 0;
      justify-content: center; }
      .content-slider--vertical .content-slider__wrapper .content-slider__content {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: unset;
        max-height: unset;
        justify-content: center;
        width: calc(100% - 50px);
        height: 100%; }
        .content-slider--vertical .content-slider__wrapper .content-slider__content .main-image {
          position: absolute;
          top: 0; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .main-image_foward {
            position: absolute;
            transform: translateY(-100%);
            transition: transform 0.5s;
            -webkit-transition: transform 0.5s;
            transition-timing-function: ease-out; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .main-image_backward {
            position: absolute;
            transform: translateY(100%);
            transition: transform 0.5s;
            -webkit-transition: transform 0.5s;
            transition-timing-function: ease-out; }
        .content-slider--vertical .content-slider__wrapper .content-slider__content .previous-image {
          position: absolute;
          bottom: 100%; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .previous-image_foward {
            position: absolute;
            bottom: 100%;
            transform: translateY(-100%);
            transition: transform 0.5s;
            -webkit-transition: transform 0.5s;
            transition-timing-function: ease-out; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .previous-image_backward {
            position: absolute;
            bottom: 100%;
            transform: translateY(100%);
            transition: transform 0.5s;
            -webkit-transition: transform 0.5s;
            transition-timing-function: ease-out; }
        .content-slider--vertical .content-slider__wrapper .content-slider__content .next-image {
          position: absolute;
          top: 100%; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .next-image_foward {
            position: absolute;
            top: 100%;
            transform: translateY(-100%);
            transition: transform 0.5s;
            -webkit-transition: transform 0.5s;
            transition-timing-function: ease-out; }
          .content-slider--vertical .content-slider__wrapper .content-slider__content .next-image_backward {
            display: none; }
    .content-slider--vertical .content-slider__wrapper:nth-child(2) {
      width: unset; }
    .content-slider--vertical .carousel-controls {
      height: 100%;
      width: 50px;
      flex-direction: column; }
      .content-slider--vertical .carousel-controls .icon-btn {
        transform: rotate(90deg); }
      .content-slider--vertical .carousel-controls .dots-pagination {
        margin-top: 14px;
        margin-bottom: 14px; }
  .content-slider .carousel-controls .input[disabled] {
    opacity: 1;
    border: 0; }
  .content-slider .inline-message {
    position: relative;
    top: 50%; }
    .content-slider .inline-message__text:before {
      display: unset;
      padding-right: 8px; }
