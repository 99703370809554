// Input select
.#{$input-select-class-name} {
  padding-top: $input-select-padding-top;
  padding-right: $input-select-padding-right;
  background-position: $input-select-arrow-icon-position;
  background-size: $input-select-arrow-icon-size;
  appearance: none;
  @include input-select-arrow-icon($input-select-arrow-icon-color);

  option {
    padding: 0;
  }

  ~ .form__icon svg {
    color: $input-icon-color-focus;
  }

  // Hide arrow for IE10-11
  // sass-lint:disable no-vendor-prefixes
  &::-ms-expand {
    display: none;
  }

  // Avoid IE10-11 defaults for select focus
  &::-ms-value {
    background: none;
    color: $input-color;
  }

  // Remove mozilla dotted outline
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-black;
  }
  // sass-lint:enable no-vendor-prefixes

  &[multiple] {
    height: auto;
    min-height: auto;
    padding-right: 0;
    background-image: none;
  }
}