$content-slider-class: #{$prefix}content-slider;
$content-slider-content-max-width: 375px;
$content-slider-content-max-height: 100px;
$content-slider-content-item-margin: 8px;
$content-slider-wrapper-margin-bottom: 12px;
$content-slider-nav-margin: 16px;
$content-slider-bullets-margin: 8px;
$content-slider-bullets-size: 8px;
$content-slider-bullets-height: 32px;
$content-slider-bullets-color: $color-grey-34;
$content-slider-bullets-hover-color: $color-grey;
$content-slider-bullets-active-color: $color-blue;
$content-slider-pagination-min-width: 64px;
$content-slider-pagination-color: $color-blue;
$content-slider-pagination-font-weight: $font-weight-bold;
