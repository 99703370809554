// Radio
.#{$input-radio-class-name} {
  $click-area-size: $input-radio-click-area-size;
  $inner-area-size: $input-radio-size - $input-radio-border-size;
  $big-circle: '+ label:before';
  $small-circle: '+ label:after';

  + label {
    height: $click-area-size;
    margin-right: $input-radio-margin-right;
    padding-right: $input-radio-text-padding;
    padding-left: $input-radio-text-left-padding;
    @include transition-with-curve ($input-radio-transition-curve, $input-radio-transition-duration, color);
  }

  &:not(:disabled) {
    + label {
      cursor: pointer;
    }
  }

  #{$big-circle},
  #{$small-circle} {
    display: block;
    position: absolute;
    top: 50%;
    left: px-to-rem($input-radio-size / 2);
    border-radius: 50%;
    background-color: $input-radio-background;
    content: '';
  }

  #{$big-circle} {
    @include input-radio-center-element($input-radio-size);
    border: $input-radio-border-size $input-radio-border-style $input-radio-border-color;
    @include transition-with-curve ($input-radio-transition-curve, $input-radio-transition-duration, background-color, border);

    @at-root .input--small#{&} {
      @include input-radio-center-element($input-small-size);
    }
  }

  #{$small-circle} {
    @include input-radio-center-element($input-radio-size);
    transform: scale(0);
    @include transition-with-curve ($input-radio-transition-curve, $input-radio-transition-duration, transform);

    @at-root .input--small#{&} {
      @include input-radio-center-element($input-small-size);
    }
  }

  &:hover:not(:disabled) #{$big-circle} {
    border-color: $input-radio-hover-color;
    background-color: $input-radio-hover-background-color;
  }

  &:active:not(:disabled) #{$big-circle} {
    border-color: $input-radio-active-color;
  }

  &:focus #{$big-circle} {
    border-color: $input-radio-focus-color;
    background-color: $input-radio-hover-background-color;
    box-shadow: $button-shadow;
  }

  &:checked {
    #{$small-circle} {
      transform: scale(.35);
    }

    #{$big-circle} {
      border-color: $input-radio-checked-background-color;
      background-color: $input-radio-checked-background-color;
     }

    &:hover {
      #{$big-circle} {
        border-color: $input-radio-hover-color;
        background-color: $input-radio-hover-color;
      }
    }
  }

  &[disabled] {
    #{$big-circle},
    + label {
      opacity: $input-disabled-opacity;
    }

    &:hover + label {
      color: inherit;
    }
  }
}
