﻿// The transition CSS property is a shorthand property for transition-property, transition-duration, transition-timing-function, and transition-delay
@mixin transition($properties...) {
  @if length($properties) >= 1 {
    transition: $properties;
  } @else {
    $properties: all .15s ease-out 0s;
    transition: $properties;
  }
}

// The transition-property CSS property is used to specify the names of CSS properties to which a transition effect should be applied.
@mixin transition-property($properties...) {
  transition-property: transition-property-names($properties);
}

// The transition-duration CSS property specifies the number of seconds or milliseconds a transition animation should take to complete
@mixin transition-duration($times...) {
  transition-duration: $times;
}

// The transition-timing-function CSS property is used to describe how the intermediate values of the CSS properties being affected by a transition effect are calculated.
@mixin transition-timing-function($motions...) {
  // ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier()
  transition-timing-function: $motions;
}

// Shorthand mixin. Supports multiple parentheses-deliminated values for each variable.
@mixin transition-delay($times...) {
  transition-delay: $times;
}
