$breadcrumb-class-name: #{$prefix}breadcrumb !default;
$breadcrumb-font-size: px-to-rem(14px) !default;
$breadcrumb-font-weight: $font-weight-bold;
$breadcrumb-link-color: $color-blue !default;
$breadcrumb-item-color: $color-grey-120 !default;
$breadcrumb-horizontal-margin: 1rem;
$breadcrumb-icon-height: 14px !default;
$breadcrumb-icon-width: 12px !default;
$breadcrumb-icon-right: -17px !default;
$breadcrumb-icon-top: 5px !default;
$breadcrumb-margin-right: 18px !default;
