.topbar {
  top: 0;
  flex: none; }
  .topbar .topbar__mobile {
    z-index: 5500; }
  .topbar .tabs__tab-active-bar {
    bottom: 0px; }
  .topbar .tabs a {
    text-overflow: ellipsis;
    white-space: nowrap; }
  .topbar .topbar__nav ul {
    list-style: none; }

.topbar__logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.topbar__brand {
  overflow: hidden; }

.topbar__brand-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.topbar__logo.topbar__logo--desktop {
  width: 4.25rem;
  height: 1.011875rem;
  background-image: url("./images/unit4-logo.svg"); }

.topbar__logo.topbar__logo--mobile {
  width: 2.543125rem;
  height: 1.4675rem;
  background-image: url("./images/u4-logo.svg"); }

.topbar__menu-icon .svg-inline--fa {
  width: 16px;
  height: 16px; }

.topbar__row--top {
  max-height: 38px; }

@media (max-width: 959.98px) {
  .topbar__row--top {
    max-height: 54px; } }
