// Font face declarations
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-regular', 400);
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-italic', 400, italic);
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-300', 300);
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-300italic', 300, italic);
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-600', 600);
@include font-face('OpenSans', '../fonts/open-sans-v15-latin-600italic', 600, italic);

// Text transform
.#{$class-name-typography}-lowercase { text-transform: lowercase; }
.#{$class-name-typography}-uppercase { text-transform: uppercase; }

// Text styles
.#{$class-name-typography}-italic {
  font-style: italic;
}

.#{$class-name-typography}-bold {
  font-weight: $font-weight-bold;
}

em {
  font-family: $font-family-sans-serif;
}

// Caption styles
small,
figcaption {
  display: inline-block;
  font-size: px-to-rem($caption-font-size);
  line-height: $caption-line-height;
}

// Body styles
body {
  background-color: $color-white;
  font-family: $font-family-base;
  font-size: px-to-rem($font-size-body);
  line-height: $line-height-base;
  // sass-lint:disable no-vendor-prefixes
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // sass-lint:enable no-vendor-prefixes
}

// Body2 styles
.body2 {
  @include body2-font-style;
}

// Headings
h1,
.headline {
  margin-bottom: $h1-margin-bottom;
  font-size: px-to-rem($h1-font-size);
  font-weight: $font-weight-normal;
  line-height: $h1-line-height;
}

h2,
.title {
  margin-bottom: $h2-margin-bottom;
  font-size: px-to-rem($h2-font-size);
  font-weight: $font-weight-normal;
  line-height: $h2-line-height;
}

h3,
.subheading-2 {
  margin-bottom: $h3-margin-bottom;
  font-size: px-to-rem($h3-font-size);
  font-weight: $font-weight-bold;
  line-height: $h3-line-height;
}

h4,
.subheading-1 {
  margin-bottom: $h4-margin-bottom;
  font-size: px-to-rem($h4-font-size);
  font-weight: $font-weight-normal;
  line-height: $h4-line-height;
}

h5,
.body-2 {
  margin-bottom: $h5-margin-bottom;
  font-size: px-to-rem($h5-font-size);
  font-weight: $font-weight-bold;
  line-height: $h5-line-height;
}

h6,
.body-1 {
  margin-bottom: $h6-margin-bottom;
  font-size: px-to-rem($h6-font-size);
  font-weight: $font-weight-normal;
  line-height: $h6-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

// Display classes
.display-3 {
  margin-bottom: $display3-margin-bottom;
  font-size: px-to-rem($display3-font-size);
  font-weight: $display3-weight;
  line-height: $display3-line-height;
}

.display-2 {
  margin-bottom: $display2-margin-bottom;
  font-size: px-to-rem($display2-font-size);
  font-weight: $display2-weight;
  line-height: $display2-line-height;
}

.display-1 {
  margin-bottom: $display1-margin-bottom;
  font-size: px-to-rem($display1-font-size);
  font-weight: $display1-weight;
  line-height: $display1-line-height;
}
