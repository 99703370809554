.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .spinner-wrapper .spinner {
    position: relative; }
    .spinner-wrapper .spinner .double-bounce1,
    .spinner-wrapper .spinner .double-bounce2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.6;
      background: #4a5c69;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out; }
    .spinner-wrapper .spinner .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s; }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
