$tooltip-dialog-class: #{$prefix}tooltip-dialog !default;
$tooltip-dialog-min-width: 256px;
$tooltip-dialog-max-width: 512px;
$tooltip-dialog-margin-horizontal: 14px;
$tooltip-dialog-padding: 1rem;
$tooltip-dialog-border-radius: 4px;
$tooltip-dialog-background-color: $color-darkgrey-unit4;
$tooltip-dialog-box-shadow: 0 4px 20px 0 rgba(76, 83, 88, .4);
$tooltip-dialog-pointer-horizontal-offset: -10px;
$tooltip-dialog-pointer-vertical-offset: 20px;
$tooltip-dialog-pointer-size: 24px;
$tooltip-dialog-title-margin-bottom: 4px;
$tooltip-dialog-title-color: $color-white;
$tooltip-dialog-title-font-weight: $font-weight-bold;
$tooltip-dialog-title-font-size: 1rem;
$tooltip-dialog-close-click-area: 24px;
$tooltip-dialog-close-icon-size: 14px;
$tooltip-dialog-close-top: 16px;
$tooltip-dialog-close-right: 10px;
$tooltip-dialog-content-color: $color-grey-34;
$tooltip-dialog-content-font-size: px-to-rem(12px);
$tooltip-dialog-buttons-padding-top: 1rem;
$tooltip-dialog-buttons-margin: 0 8px 0 0;

@mixin close-icon($color) {
  background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="' + $color + '"><path fill-rule="evenodd" d="M57.9 48l21.8-21.8c1.6-1.6 1.6-4.2 0-5.7l-4.2-4.2c-1.6-1.6-4.2-1.6-5.7 0L48 38.1 26.2 16.3c-1.6-1.6-4.2-1.6-5.7 0l-4.2 4.2c-1.6 1.6-1.6 4.2 0 5.7L38.1 48 16.3 69.8c-1.6 1.6-1.6 4.2 0 5.7l4.2 4.2c1.6 1.6 4.2 1.6 5.7 0L48 57.9l21.8 21.8c1.6 1.6 4.2 1.6 5.7 0l4.2-4.2c1.6-1.6 1.6-4.2 0-5.7L57.9 48z" clip-rule="evenodd"/></svg>');
}

// Tooltip dialog error
$tooltip-dialog-error-background-color: $color-alert;
$tooltip-dialog-error-margin-horizontal: 8px;
$tooltip-dialog-error-padding: .5rem 1rem;
$tooltip-dialog-error-pointer-horizontal-offset: -6px;
$tooltip-dialog-error-pointer-vertical-offset: 16px;
$tooltip-dialog-error-pointer-size: 16px;
$tooltip-dialog-error-content-color: $color-white;