// Labels
.#{$label-class-name} {
  display: flex;
  order: 1;
  margin-bottom: $label-margin-bottom;
  color: $label-color;
  font-size: $label-size;
  line-height: $label-line-height;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @at-root .#{$prefix}form__item--inline .#{$label-class-name} {
    display: block;
    margin-right: $label-inline-margin-right;
    line-height: $label-inline-line-height;
  }
}