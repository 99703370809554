.divider {
  display: flex;
  border: 0;
  border-top: $divider-border-width solid $divider-border-color;

  &:not(hr) {
    height: $divider-border-width;
  }

  &--vertical {
    width: $divider-border-width;
    height: 100%;
    border-right: $divider-border-width solid $divider-border-color;
  }

  &--heading {
    position: relative;
    background-color: $divider-heading-background;
    color: $divider-heading-color;

    &:after {
      display: block;
      margin: $divider-heading-margin;
      padding: $divider-heading-padding;
      background-color: inherit;
      font-size: $divider-heading-font-size;
      text-transform: capitalize;
      content: attr(heading);
    }
  }

  &--light-grey {
    background-color: $divider-heading-light-grey-bg;
  }

  &--white {
    background-color: $divider-heading-white-bg;
    color: $divider-heading-white-color;
  }
}

