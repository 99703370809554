﻿// !important is needed for some visibility classes

// Makes an element visually hidden, but still accessible to keyboards and assistive devices.
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility Hiding Content for Accessibility
@mixin element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

// Reverses the CSS output created by the `element-invisible()` mixin.
@mixin element-invisible-off {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}
