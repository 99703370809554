﻿// Input wrapper styles with size and inline modifiers
.#{$prefix}form {
  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    min-width: $form-item-width-medium;
    margin: $form-item-margin;

    &--inline {
      flex-direction: row;
      align-items: center;
      max-height: $input-height;
    }

    &--small {
      min-width: $form-item-width-small;
      max-width: $form-item-width-small;
    }

    &--medium {
      min-width: $form-item-width-medium;
      max-width: $form-item-width-medium;
    }

    &--large {
      min-width: $form-item-width-large;
      max-width: $form-item-width-large;
    }

    &--checkbox,
    &--radio {
      display: block;
      min-width: $form-item-checkbox-min-width;
      margin-right: $form-item-checkbox-margin-right;

      &--small {
        height: $input-small-size;
        margin-bottom: $input-small-margin-bottom;
      }
    }

    &--icon {
      position: relative;

      .#{$input-select-class-name},
      .#{$input-class-prefix} {
        padding-left: $input-icon-padding-left;
      }

      .form__icon {
        position: absolute;
      }

      label + .form__icon {
        top: 24px;
      }
    }

    &--switch {
      flex-direction: row;
      margin-right: $form-item-checkbox-margin-right;

      &--small {
        margin-bottom: $input-small-margin-bottom;
      }
    }

    &--file {
      flex-direction: row;
      align-items: center;
    }
  }

  &--inline {
    display: inline-flex;
    width: auto;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $form-icon-wrapper-size;
    height: $form-icon-wrapper-size;

    .#{$fa-icon-selector} {
      width: $input-icon-size;
      height: $input-icon-size;
      margin: 0;
    }
  }
}

// Wrapper class for radio & checkboxes
.#{$prefix}form-input {
  margin-bottom: $input-wrapper-bottom-margin;

  &:last-child {
    margin: 0;
  }
}

// Radio & Checkbox
.#{$input-radio-class-name},
.#{$input-checkbox-class-name} {
  position: absolute;
  width: auto;
  clip: rect(0, 0, 0, 0);

  + label {
    @include user-select(none);
    display: inline-block;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
  }

  @at-root #{selector-append('.input--small', &)} {
    + label {
      height: $input-small-size;
      padding-left: $input-small-padding-left;
      line-height: $input-small-size;
    }
  }

  &:focus + label {
    color: $label-focus-color;
  }
}

// File input
.#{$input-file-class} {
  position: absolute;
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.#{$input-file-name-class} {
  padding: 0 $input-file-name-padding;
}

.#{$input-file-label-class} {
  @include button-size(small);
  @include button-style();
  cursor: pointer;

  &:focus,
  &.#{$label-class-name}--focus {
    outline: none;
    box-shadow: 0 0 $input-focus-outerglow-distance $color-blue;
  }
}

// Form groups - designed to help with the organization and spacing of vertical forms.
.#{$prefix}form-group {
  margin-bottom: $group-margin-bottom;
}
