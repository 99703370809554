// Input group
.#{$input-class-prefix}-group {
  display: flex;
  width: 100%;
  min-width: $form-item-width-medium;
  max-height: $input-height;
  margin: $form-item-margin;
  background-color: $input-background-color;

  .#{$input-class-prefix} {
    flex: 1;
  }

  &__prepend,
  &__append {
    display: flex;

    .#{$prefix}btn {
      min-width: auto;
      margin: 0;
      padding: 0;
      line-height: $input-group-icon-line-height;

      &:focus {
        z-index: 1;
      }

      .form__icon {
        width: $input-group-icon-width;
        height: 100%;
      }
    }
  }

  &__prepend {
    .#{$input-class-prefix}-group__text,
    .#{$input-class-prefix}-group__icon,
    .#{$prefix}btn {
      margin-right: -1px;
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }
  }

  &__append {
    .#{$input-class-prefix}-group__text,
    .#{$input-class-prefix}-group__icon,
    .#{$prefix}btn {
      margin-left: -1px;
      border-radius: 0 $input-border-radius $input-border-radius 0;
    }

    + .#{$input-class-prefix} {
      order: -1;
    }
  }

  &__text,
  &__icon {
    border: $input-border;
  }

  &__text {
    padding: $input-group-text-padding;
    color: $input-group-text-color;
    line-height: $input-group-text-line-height;
  }

  &__icon {
    .form__icon {
      width: $input-group-icon-width;
      height: 100%;
    }
  }
}
